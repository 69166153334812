import { useDispatch } from 'react-redux';
import { ISNGPRecordsData } from '../../types/sngpPool.type';
import { setSNGPPoolSlice } from './sngpPoolSlice';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  GET_SNGP_COMMUNITY_GRAPH,
  GET_SNGP_POOL_ACTIVITY,
  GET_SNGP_POOL_HISTORY,
  GET_SNGP_BANNER,
  SNGP_CLAIM,
} from '../../utils/network/ApiEndpoints';
import { toast } from 'react-toastify';

export interface ISNGPPoolScoreHistoryParams {
  page?: number;
  limit?: number;
  toDate?: any;
  fromDate?: any;
  query?: string;
  type?: string;
}

export const useSNGPPoolActions = () => {
  const dispatch = useDispatch();
  const getSNGPBannerData = async () => {
    dispatch(
      setSNGPPoolSlice({
        sngpBannerLoading: true,
      })
    );
    try {
      const response = await ApiRequestHomnifi().request({
        method: 'GET',
        url: GET_SNGP_BANNER,
      });
      if (response.status === 200) {
        dispatch(
          setSNGPPoolSlice({
            sngpBannerData: response.data.data,
            sngpBannerLoading: false,
          })
        );

        return response.data.data;
      }
    } catch (error: any) {
      if (error?.data) {
        toast.error(error?.data.message);
      }
      dispatch(
        setSNGPPoolSlice({
          sngpBannerLoading: false,
        })
      );
      return error;
    }

    //  .then((response) => {
    //    const {
    //      data: { data },
    //    } = response;

    //  dispatch(
    //    setSNGPPoolSlice({
    //      activity,
    //      totalSngp,
    //      totalClaimed,
    //      sngpRecords,
    //    })
    //  );
  };

  const getSNGPActivity = async () => {
    dispatch(
      setSNGPPoolSlice({
        activityLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_SNGP_POOL_ACTIVITY,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        const activity = {
          balance: data?.totalSngp - data?.globalSngpUsed,
          generated: data?.globalSngpUsed,
        };

        const claimedDetails = {
          claimedAmount: data?.claimedTotal || 0,
          claimedDate: data?.lastClaimedTime || 0,
          rewardToken: data?.rewardToken || '',
        };

        const totalSngp = {
          totalSngp: data?.totalSngp || 0,
        };

        const totalClaimed = {
          globalSngpUsed: data?.globalSngpUsed || 0,
        };

        const sngpRecords: Array<ISNGPRecordsData> = [
          {
            label: 'Total SNGP Given Out',
            totalRecords: data?.totalSngp || 0,
            generatedRecords: data?.globalSngpUsed || 0,
          },
          {
            label: 'Your SNGP',
            totalRecords: data?.totalSngp || 0,
            generatedRecords: data?.userSngp || 0,
          },
          {
            label: 'Total SNGP In Your Community',
            totalRecords: data?.totalSngp || 0,
            generatedRecords: data?.teamTotalSngp || 0,
          },
        ];
        dispatch(
          setSNGPPoolSlice({
            activity,
            totalSngp,
            totalClaimed,
            sngpRecords,
            claimedDetails,
          })
        );
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setSNGPPoolSlice({
            activityLoading: false,
          })
        );
      });
  };

  const getSNGPCommunityGraph = async ({ timeline }: { timeline: string }) => {
    dispatch(
      setSNGPPoolSlice({
        userSNGPCommunityLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_SNGP_COMMUNITY_GRAPH,
        params: {
          timeline,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSNGPPoolSlice({
            userSNGPCommunity: data,
          })
        );
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setSNGPPoolSlice({
            userSNGPCommunityLoading: false,
          })
        );
      });
  };

  // const getSNGPTotalReward = async () => {
  //   dispatch(
  //     setSNGPPoolSlice({
  //       totalSNGPLoading: true,
  //     })
  //   );

  //   return await ApiRequestHomnifi()
  //     .request({
  //       method: 'GET',
  //       url: GET_TOTAL_SNGP_REWARD,
  //     })
  //     .then((response) => {
  //       const {
  //         data: { data },
  //       } = response;
  //       dispatch(
  //         setSNGPPoolSlice({
  //           rewards: data,
  //         })
  //       );
  //       return response;
  //     })
  //     .catch((error) => {
  //       if (error.data) {
  //         toast.error(error.data.message);
  //       }
  //       return error;
  //     })
  //     .finally(() => {
  //       dispatch(
  //         setSNGPPoolSlice({
  //           totalSNGPLoading: false,
  //         })
  //       );
  //     });
  // };

  const getSNGPScoreHistory = async ({
    page = 1,
    limit = 10,
    fromDate,
    toDate,
    query,
    type,
  }: ISNGPPoolScoreHistoryParams) => {
    dispatch(
      setSNGPPoolSlice({
        historyLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_SNGP_POOL_HISTORY,
        params: {
          query,
          page,
          limit,
          fromDate,
          toDate,
          type,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSNGPPoolSlice({
            history: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setSNGPPoolSlice({
            historyLoading: false,
          })
        );
      });
  };
  const claimSNGPPoolReward = async () => {
    dispatch(
      setSNGPPoolSlice({
        claimLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: SNGP_CLAIM,
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setSNGPPoolSlice({
              sngpBannerData: {
                totalPoint: 0,
                totalSngpPoints: 0,
                rewardTokenSymbol: '',
                rewardToken: '',
                totalRewardUSD: 0,
              },
              claimLoading: false,
            })
          );
          toast.success('Reward claimed successfully!');
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          setSNGPPoolSlice({
            claimLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  return {
    getSNGPActivity,
    // getSNGPTotalScore,
    // getSNGPTotalReward,
    getSNGPCommunityGraph,
    getSNGPScoreHistory,
    getSNGPBannerData,
    claimSNGPPoolReward,
  };
};
