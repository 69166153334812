import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import '../../styles/index.css';
import { Autoplay, EffectCards } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { selectPlatorfom } from '../../store/platform/platformSlice';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';

const CardSwiper = () => {
  const { adsLoading, adsList } = useSelector(selectPlatorfom);

  return (
    <div className="w-full h-full">
      <Swiper
        effect={'cards'}
        grabCursor={true}
        className="w-3/4 xl:w-full adsSwiper"
        modules={[EffectCards, Autoplay]}
        cardsEffect={{
          slideShadows: false,
          rotate: false,
          perSlideRotate: 0,
        }}
        autoplay={{
          delay: 50000,
          disableOnInteraction: true,
        }}
      >
        {adsLoading ? (
          <div className="w-full h-60 md:h-80 rounded-lg flex items-center justify-center bg-[#D2E2F9]">
            <Spinner className="animate-spin h-16" />
          </div>
        ) : (
          adsList.map(
            (card: any, index: number) =>
              card.status === 'active' && (
                <SwiperSlide key={index} className="rounded-2xl">
                  <img
                    src={card.imageUrl || ''}
                    alt={card.title}
                    className="object-cover w-full h-full rounded-lg"
                  />
                </SwiperSlide>
              )
          )
        )}
      </Swiper>
    </div>
  );
};

export default CardSwiper;
