import WarningIcon from '../../../../SVGIcons/warningIcon';

const GenerateAddressWarning = ({
  color,
  shadowColor,
  text,
  loading = false,
}: {
  color: string;
  shadowColor: string;
  text: string;
  loading?: boolean;
}) => {
  const GenerateAddressWarningSkeleton = () => {
    return (
      <div className="flex md:w-[478px] md:h-[40px] gap-2 items-center border rounded-md border-[#E6E6E6] animate-pulse">
        <div className="flex items-center h-full p-1 bg-gray-200">
          <div className="w-[24px] h-[24px] bg-gray-200 rounded-full"></div>
        </div>

        <div className="flex flex-col">
          <div className="w-[150px] h-[12px] bg-gray-200 rounded mb-1"></div>
          <div className="w-[70px] h-[10px] bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  };

  if (loading) return <GenerateAddressWarningSkeleton />;

  return (
    <div
      className={`flex md:w-[478px] md:h-[40px] gap-2  border rounded-md overflow-hidden`}
      style={{
        borderColor: color,
        boxShadow: `0px 5px 5px 0px ${shadowColor}`,
      }}
    >
      <div className={`flex p-1`} style={{ backgroundColor: color }}>
        <WarningIcon color={color} className="self-center" />
      </div>

      <span className="text-[#7D7D7D] self-center text-[12px] font-normal leading-[14.22px] text-left p-1">
        {text}
      </span>
    </div>
  );
};

export default GenerateAddressWarning;
