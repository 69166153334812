import React from 'react';

const CloudKIcon = ({ color = '#1B9FDA' }: any) => {
  return (
    <svg
      width="35"
      height="27"
      viewBox="0 0 35 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10567_18937)">
        <path
          d="M22.454 7.91797C22.7631 7.91797 23.0164 8.17127 23.0164 8.48039C23.0164 8.7895 22.7631 9.0428 22.454 9.0428C22.1449 9.0428 21.8916 8.7895 21.8916 8.48039C21.8916 8.17127 22.1449 7.91797 22.454 7.91797Z"
          fill={color}
        />
        <path
          d="M19.8996 4.14841C19.9854 4.14841 21.8487 4.62497 21.9346 6.87893C21.9346 7.18375 22.1965 7.39841 22.497 7.39841C22.7975 7.39841 23.0165 7.13653 23.0165 6.836C22.9736 5.23461 22.1922 4.28151 21.5439 3.80066C20.8484 3.28118 20.2001 3.10515 20.1572 3.10515C19.8524 3.01929 19.5948 3.23395 19.5089 3.49584C19.423 3.75773 19.5948 4.05826 19.8996 4.14412V4.14841Z"
          fill={color}
        />
        <path
          d="M17.6025 11.7303C14.0048 11.7303 11.1025 14.6326 11.1025 18.2303C11.1025 21.8281 14.0048 24.7303 17.6025 24.7303C21.2003 24.7303 24.1025 21.8281 24.1025 18.2303C24.1025 14.6326 21.1574 11.7303 17.6025 11.7303ZM17.6025 23.6484C14.6144 23.6484 12.1844 21.2227 12.1844 18.2303C12.1844 15.2379 14.6101 12.8122 17.6025 12.8122C20.5949 12.8122 23.0206 15.2379 23.0206 18.2303C23.0206 21.2227 20.552 23.6484 17.6025 23.6484Z"
          fill={color}
        />
        <path
          d="M26.2235 17.4533C28.3916 16.762 29.8212 14.7227 29.8212 12.4688C29.8212 9.82413 27.7433 7.61739 25.1416 7.3555C25.4035 3.89084 22.5399 0.898438 18.9893 0.898438C16.7783 0.898438 14.7862 2.0705 13.7043 3.84362C11.0597 3.01931 8.37638 4.83965 8.15742 7.52724C5.94639 8.17552 4.38794 10.1719 4.38794 12.4688C4.38794 14.8945 5.94639 17.0626 8.11449 17.6679C8.24329 17.7109 8.24329 17.6679 8.98173 17.6679C8.67691 22.6953 12.6654 26.8984 17.6498 26.8984C22.6343 26.8984 26.6614 22.5665 26.2278 17.4533H26.2235ZM8.28622 16.629C6.63761 16.1524 5.42691 14.4179 5.42691 12.5117C5.42691 10.6055 6.81363 8.91396 8.76277 8.52327H8.84864C9.19639 8.52327 10.3642 8.52327 11.2314 9.47638C11.4461 9.69104 11.7509 9.73827 12.0128 9.51931C12.2274 9.30465 12.2747 8.99982 12.0557 8.73794C11.1455 7.69897 9.97777 7.43708 9.28226 7.39414C9.58708 5.35914 11.841 4.14414 13.7473 5.01138C14.0091 5.14018 14.3097 5.01138 14.4428 4.74949C15.31 3.01502 17.0445 1.97605 18.9936 1.97605C22.1148 1.97605 24.5405 4.70656 24.021 7.78483C23.9781 8.13259 24.2357 8.43311 24.5834 8.43311C26.9233 8.39018 28.7866 10.2535 28.7866 12.5074C28.7866 14.2848 27.6617 15.8433 26.099 16.4057C25.2317 12.5074 21.7671 9.60088 17.6498 9.60088C13.5326 9.60088 9.8919 12.6362 9.15775 16.6633H8.29052V16.6204L8.28622 16.629ZM17.6026 25.8165C13.4424 25.8165 10.0207 22.3948 10.0207 18.2346C10.0207 14.0745 13.3995 10.6527 17.6026 10.6527C21.8057 10.6527 25.1845 14.0745 25.1845 18.2346C25.1845 22.3948 21.7628 25.8165 17.6026 25.8165Z"
          fill={color}
        />
        <path
          d="M17.3537 21.9526V21.2443C17.0016 21.2013 16.714 21.1197 16.4907 21.0081C16.2675 20.8965 16.0786 20.7119 15.9154 20.4543C15.7523 20.201 15.6621 19.8876 15.6321 19.5227L16.3448 19.3896C16.4006 19.7717 16.495 20.0507 16.6367 20.2268C16.8342 20.4801 17.0746 20.6174 17.3537 20.6475V18.3935C17.0617 18.3377 16.7655 18.2261 16.4607 18.0544C16.2374 17.9299 16.0614 17.7538 15.9412 17.5306C15.821 17.3073 15.7566 17.054 15.7566 16.7707C15.7566 16.2684 15.9369 15.8605 16.2932 15.5471C16.5337 15.3367 16.8857 15.2079 17.3537 15.1607V14.8215H17.7701V15.1607C18.1823 15.1993 18.5086 15.3196 18.749 15.5213C19.0581 15.7789 19.2427 16.131 19.3071 16.5775L18.5773 16.6891C18.5343 16.41 18.4485 16.1997 18.3154 16.0494C18.1823 15.8991 18.002 15.8047 17.7701 15.7575V17.7968C18.1265 17.8869 18.3626 17.9556 18.4785 18.0071C18.6975 18.1059 18.8778 18.2218 19.0152 18.3592C19.1526 18.4966 19.2599 18.664 19.3372 18.8529C19.4144 19.0418 19.4488 19.2522 19.4488 19.4754C19.4488 19.9649 19.2942 20.3727 18.9808 20.7033C18.6674 21.0296 18.2638 21.2056 17.7701 21.2314V21.9483H17.3537V21.9526ZM17.3537 15.7575C17.0789 15.8004 16.86 15.9077 16.7011 16.088C16.5423 16.2684 16.465 16.4787 16.465 16.7192C16.465 16.9596 16.5337 17.1614 16.6668 17.3245C16.7998 17.4876 17.0317 17.6164 17.3537 17.7152V15.7575ZM17.7701 20.6475C18.0449 20.6131 18.2724 20.4929 18.4528 20.2911C18.6331 20.0851 18.7232 19.8361 18.7232 19.5312C18.7232 19.2736 18.6588 19.0676 18.5343 18.913C18.4098 18.7585 18.1522 18.6168 17.7744 18.4966V20.6518L17.7701 20.6475Z"
          fill={color}
        />
        <path
          d="M8.40214 10.2535C8.01575 9.86712 7.39322 9.86712 7.00683 10.2535L5.66304 11.5973V6.1792C5.66304 5.63396 5.22083 5.19604 4.67988 5.19604C4.13893 5.19604 3.69672 5.63825 3.69672 6.1792V11.5973L2.35293 10.2535C1.96654 9.86712 1.34401 9.86712 0.961914 10.2535C0.575521 10.6399 0.575521 11.2624 0.961914 11.6445L3.88563 14.5682C4.10029 14.7829 4.38364 14.8988 4.68417 14.8988C4.9847 14.8988 5.27235 14.7829 5.48272 14.5682L8.40643 11.6445C8.79283 11.2581 8.79283 10.6356 8.40643 10.2492L8.40214 10.2535Z"
          fill="white"
        />
        <path
          d="M4.67556 15.3324C4.25912 15.3324 3.86414 15.1693 3.57219 14.873L0.648481 11.9493C0.0989437 11.3998 0.0989437 10.5025 0.648481 9.94867C1.18085 9.41201 2.11678 9.41631 2.64914 9.94867L3.26308 10.5626V6.18348C3.26308 5.40211 3.89848 4.771 4.67556 4.771C5.45264 4.771 6.08805 5.4064 6.08805 6.18348V10.5626L6.70198 9.94867C6.96816 9.68249 7.3245 9.53652 7.70231 9.53652C8.08012 9.53652 8.43646 9.68249 8.70264 9.95296C9.25218 10.5025 9.25218 11.3998 8.70264 11.9536L5.77893 14.8773C5.48699 15.1736 5.09201 15.3324 4.67556 15.3324ZM1.64881 10.3952C1.49855 10.3952 1.36116 10.451 1.25383 10.5583C1.03917 10.773 1.03917 11.1293 1.25383 11.344L4.17754 14.2677C4.44373 14.5339 4.9074 14.5339 5.16929 14.2677L8.093 11.344C8.30766 11.1293 8.31195 10.773 8.093 10.5583C7.87404 10.3437 7.52199 10.3437 7.30733 10.5583L5.22939 12.6363V6.18348C5.22939 5.87866 4.98038 5.62965 4.67556 5.62965C4.37074 5.62965 4.12173 5.87866 4.12173 6.18348V12.6363L2.04379 10.5583C1.93646 10.451 1.79908 10.3952 1.64881 10.3952Z"
          fill={color}
        />
        <path
          d="M34.1617 12.4001C33.7753 12.0137 33.1527 12.0137 32.7663 12.4001L31.4226 13.7439V8.32581C31.4226 7.78056 30.9804 7.34265 30.4394 7.34265C29.8985 7.34265 29.4562 7.78486 29.4562 8.32581V13.7439L28.1125 12.4001C27.7261 12.0137 27.1035 12.0137 26.7214 12.4001C26.335 12.7865 26.335 13.409 26.7214 13.7911L29.6451 16.7148C29.8598 16.9295 30.1432 17.0454 30.4437 17.0454C30.7442 17.0454 31.0319 16.9295 31.2422 16.7148L34.166 13.7911C34.5523 13.4047 34.5523 12.7822 34.166 12.3958L34.1617 12.4001Z"
          fill="white"
        />
        <path
          d="M30.4351 17.479C30.0186 17.479 29.6237 17.3159 29.3317 17.0196L26.408 14.0959C25.8585 13.5464 25.8585 12.6491 26.408 12.0953C26.9404 11.5586 27.8763 11.5629 28.4087 12.0953L29.0226 12.7092V8.33009C29.0226 7.54871 29.658 6.9176 30.4351 6.9176C31.2122 6.9176 31.8476 7.55301 31.8476 8.33009V12.7092L32.4615 12.0953C32.7277 11.8291 33.084 11.6831 33.4618 11.6831C33.8396 11.6831 34.196 11.8291 34.4622 12.0996C35.0117 12.6491 35.0117 13.5464 34.4622 14.1002L31.5385 17.0239C31.2465 17.3202 30.8515 17.479 30.4351 17.479ZM27.4083 12.5418C27.2581 12.5418 27.1207 12.5976 27.0134 12.7049C26.7987 12.9196 26.7987 13.2759 27.0134 13.4906L29.9371 16.4143C30.2032 16.6805 30.6669 16.6805 30.9288 16.4143L33.8525 13.4906C34.0672 13.2759 34.0715 12.9196 33.8525 12.7049C33.6336 12.4903 33.2815 12.4903 33.0669 12.7049L30.9889 14.7829V8.33009C30.9889 8.02526 30.7399 7.77626 30.4351 7.77626C30.1303 7.77626 29.8813 8.02526 29.8813 8.33009V14.7829L27.8033 12.7049C27.696 12.5976 27.5586 12.5418 27.4083 12.5418Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10567_18937">
          <rect
            width="34.6423"
            height="26"
            fill="white"
            transform="translate(0.236328 0.898438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloudKIcon;
