/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useSideBarToggler from '../../hooks/useSideBarToggler';
import useUserCheck from '../../hooks/useUserCheck';
import {
  LegalBookIcon,
  LogoutIcon,
  SupportIcon,
  WikiBookIcon,
} from '../../images/svgs';
import { toggleDrawer } from '../../store/drawer/drawerSlice';
import { usePlatformAction } from '../../store/platform/platformAction';
import {
  selectMenuItems,
  toggleMenu,
} from '../../store/sidebarMenu/sideBarMenuSlice';
import { useUserActions } from '../../store/user/userActions';
import { LINKS, MEMBERSHIP_DATE } from '../../utils/constants';
import { useWindowSize } from '../../utils/helper';
import { SidebarData } from '../../utils/sidebarData';
import IconLabel from '../IconLabel';
import { combineSlices } from '@reduxjs/toolkit';
import useShowSpecialSwap from '../../hooks/useShowSpecialSwap';
import { fire } from '../../images/cloud';
import { selectCloudSlice } from '../../store/Cloudk2/clouldk2Slice';
import { selectUserSlice } from '../../store/user/userSlice';

type Props = {
  drawer: boolean;
};

const Sidebar = (props: Props) => {
  const { builderGeneral, builderReferral, baseReferral } =
    useSelector(selectUserSlice);

  const size = useWindowSize();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userLogout } = useUserActions();
  const { sideBarMenuToggler } = useSideBarToggler();
  const { ToggleMenuItems } = useSelector(selectMenuItems);
  const { user } = useUserCheck();
  const showSpecialSwap = useShowSpecialSwap();
  const { burnUserStatus } = useSelector(selectCloudSlice);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const { platformLogin } = usePlatformAction();

  // Sidebar menu bottom option handler
  const MenuBottomOptionSideBarToggler = () => {
    dispatch(toggleMenu(''));
    if (size.width <= 768) {
      dispatch(toggleDrawer(!props.drawer));
      localStorage.setItem('drawer', JSON.stringify(!props.drawer));
    }
  };

  useEffect(() => {
    if (props.drawer && size.width <= 768) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.drawer, size]);

  useEffect(() => {
    sideBarMenuToggler();
  }, [size]);

  const filterChildren = (children: any, isActiveMembership: any) => {
    return children.filter((child: any) => {
      if (child.key_value === 'cloudK-1.0') {
        return isActiveMembership;
      }
      return true;
    });
  };

  let filteredSidebarData = SidebarData.map((item) => {
    if (item.key_value === 'wallet' && item.children) {
      return {
        ...item,
        children: item.children.filter(
          (child) => child.key_value !== 'special-swap' || showSpecialSwap
        ),
      };
    }
    return item;
  });

  if (builderGeneral && builderReferral && baseReferral) {
    filteredSidebarData = filteredSidebarData.filter(
      (item) => item.key_value !== 'community'
    );
  }

  const oldDate = new Date(user?.dateJoined || '');

  const NewDate = Number(MEMBERSHIP_DATE) > Number(oldDate);

  return (
    <>
      {props.drawer && (
        <div
          className="max-[768px]:block  hidden overflow-hidden fixed top-0 left-0 w-[100vw] h-[100vh]"
          style={{ zIndex: 12 }}
        >
          <div
            className="absolute top-0 w-screen h-full bg-black/20"
            style={{ zIndex: 12 }}
            onClick={() => dispatch(toggleDrawer(!props.drawer))}
          />
        </div>
      )}
      <section
        id="hs-drawer-no-overlay"
        className={`
        ${props.drawer ? 'w-80 translate-x-0' : 'w-0 -translate-x-52'}
         border-r sticky left-0 top-[80px]  transition-all ease-in-out duration-300 bg-white ${size.width <= 760 ? 'h-[calc(100vh-150px)]' : 'h-[calc(100vh-80px)] '}
        max-[768px]:fixed z-[50]
        `}
        tabIndex={-1}
      >
        <article
          className={`hs-accordion-group p-6  w-full flex flex-col flex-wrap overflow-auto small-scroll  ${size?.width <= 760 ? 'h-[73%]' : size.height <= 750 ? 'h-[80%] ' : 'h-full'}  `}
          data-hs-accordion-always-open
        >
          <ul className="space-y-1.5">
            {filteredSidebarData.map(
              ({
                StartIcon,
                EndIcon,
                text,
                url,
                key_value = '',
                children,
                external = false,
                externalId,
                isDivider = false, // Check for divider
              }) => {
                if (isDivider) {
                  return (
                    <li key={text} className="flex items-center py-2">
                      <span className="mx-2 text-xs text-gray-300">{text}</span>
                      <div className="flex-1 border-t border-gray-300"></div>
                    </li>
                  );
                }

                return (
                  <li key={text}>
                    <Link
                      className={`w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm transition-all ease-in  hover:text-primary rounded-lg hover:bg-gray-100
                      ${location.pathname === '/' + key_value ? 'text-primary bg-gray-100' : 'text-black'}
                    `}
                      target={key_value === 'myxera' ? '_blank' : '_self'}
                      to={!external ? url || '' : '#'}
                      onClick={async () => {
                        if (external) {
                          const res = await platformLogin(externalId);
                          if (res?.status === 200) {
                            window.open(res?.data?.redirectUri, '_blank');
                          }
                          // if (key_value === 'horysmall') {
                          //   window.open(
                          //     'https://homnifi.codeinprogress.net/sso?redirect_uri=https://uat.horysmall.com/auth/&scope=openid&client_id=Dj92mCLXlE5dG5PPBWlrlmypNqj66xafgoHSNjXe&response_type=code',
                          //     '_blank'
                          //   );
                          // } else {
                          //   const res = await platformLogin(externalId);
                          //   if (res?.status === 200) {
                          //     window.open(res?.data?.redirectUri, '_blank');
                          //   }
                          // }
                        }
                        dispatch(toggleMenu(key_value));
                        ToggleMenuItems === key_value
                          ? setIsSubMenu(!isSubMenu)
                          : setIsSubMenu(true);
                        if (size.width <= 768) {
                          if (children === null) {
                            dispatch(toggleDrawer(!props.drawer));
                            localStorage.setItem(
                              'drawer',
                              JSON.stringify(!props.drawer)
                            );
                          }
                        }
                      }}
                    >
                      {StartIcon && (
                        <IconLabel
                          Icon={<StartIcon className="w-4 h-4" />}
                          label={text}
                        />
                      )}

                      {EndIcon && (
                        <EndIcon
                          className={`w-2.5 h-2.5 transition-all ${ToggleMenuItems === key_value && isSubMenu && 'rotate-90'}`}
                        />
                      )}
                    </Link>

                    {children && isSubMenu && (
                      <ul
                        className={`px-4 transition-all overflow-hidden ${ToggleMenuItems === key_value ? 'h-full' : 'h-0'}`}
                      >
                        <AnimatePresence>
                          {filterChildren(children, NewDate).map(
                            (child: any, index: number) => {
                              return (
                                <motion.div
                                  initial={{
                                    opacity: 0,
                                    y: -50,
                                  }}
                                  animate={{
                                    opacity: 1,
                                    y: 0,
                                  }}
                                  exit={{
                                    opacity: 0,
                                    y: -50,
                                  }}
                                  transition={{
                                    duration: 0.2,
                                  }}
                                  key={index}
                                >
                                  <Link
                                    to={
                                      !child.external ? child.url || '#' : '#'
                                    }
                                    onClick={async () => {
                                      if (size.width <= 768) {
                                        dispatch(toggleDrawer(!props.drawer));
                                        localStorage.setItem(
                                          'drawer',
                                          JSON.stringify(!props.drawer)
                                        );
                                      }
                                      if (child.external) {
                                        const res = await platformLogin(
                                          child.externalId
                                        );

                                        if (res?.status === 200) {
                                          window.open(
                                            res?.data?.redirectUri,
                                            '_blank'
                                          );
                                        }
                                      }
                                    }}
                                    className="flex"
                                  >
                                    <div
                                      className={`relative flex flex-col border-l border-gray-300 -mt-1.5`}
                                    >
                                      {(index === children.length - 1 ||
                                        (child.key_value === 'nodek' &&
                                          index === children.length - 2)) && (
                                        <div className="absolute bottom-0 -left-[2px] w-1 h-[0.8rem] bg-white"></div>
                                      )}
                                      <span
                                        className={`absolute top-1/2 transform -translate-y-1/2  border-gray-300 h-2 border-b w-3 ${index < children.length - 1 ? '' : 'rounded-bl-sm'}`}
                                      />
                                      {/* {index < children.length - 1 &&


                                    
                                      (child.key_value === 'nodek' ? (
                                        NewDate && (
                                          <span
                                            className={`border-l border-gray-300 h-3 ${child}`}
                                          />
                                        )
                                      ) : (
                                        <span
                                          className={`border-l border-gray-300 h-3 ${child.key_value}`}
                                        />
                                      ))} */}
                                    </div>
                                    <li
                                      className={`text-sm py-1 px-2 hover:text-primary transition-all ${location.pathname === child.url && 'text-primary'} ${child.key_value === 'nodek' ? 'flex flex-row items-center gap-1' : ''} ml-2`}
                                    >
                                      {child.text}
                                      {burnUserStatus?.activeBurn !== null &&
                                        child.key_value === 'nodek' && (
                                          <img
                                            src={fire}
                                            className="w-3.5 h-3.5 object-contain"
                                          />
                                        )}
                                    </li>
                                  </Link>
                                </motion.div>
                              );
                            }
                          )}
                        </AnimatePresence>
                      </ul>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </article>

        <article
          className={` border-borderGray border-t ${size.width <= 768 && 'h-[27%] '} absolute bottom-0 w-full px-6 py-1 flex-end content-end bg-white overflow-auto small-scroll`}
        >
          <Link
            className={`w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm transition-all delay-75 ease-in text-black hover:text-primary rounded-lg hover:bg-gray-100
                ${location.pathname === '/support' && 'bg-gray-100 text-primary'}
              `}
            to={'/support'}
            onClick={async () => {
              MenuBottomOptionSideBarToggler();
            }}
          >
            <IconLabel
              Icon={<SupportIcon className="w-4 h-4" />}
              label="Support"
            />
          </Link>
          <Link
            className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm transition-all delay-75 ease-in hover:text-primary rounded-lg hover:bg-gray-100"
            to={LINKS.WIKI || ''}
            target="_blank"
            onClick={async () => {
              MenuBottomOptionSideBarToggler();
            }}
          >
            <IconLabel
              Icon={<WikiBookIcon className="w-4 h-4" />}
              label="Wiki"
            />
          </Link>
          <Link
            className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm transition-all delay-75 ease-in hover:text-primary rounded-lg hover:bg-gray-100"
            to={LINKS.LEGAL || ''}
            target="_blank"
            onClick={async () => {
              MenuBottomOptionSideBarToggler();
            }}
          >
            <IconLabel
              Icon={<LegalBookIcon className="w-4 h-4" />}
              label="Legal"
            />
          </Link>

          <button
            className="w-full flex sm:hidden items-center gap-x-3.5 py-2 px-2.5 text-sm transition-all delay-75 ease-in hover:text-primary rounded-lg hover:bg-gray-100"
            onClick={userLogout}
          >
            <IconLabel
              Icon={<LogoutIcon className="w-4 h-4" />}
              label="Logout"
            />
          </button>
        </article>
      </section>
    </>
  );
};

export default Sidebar;
