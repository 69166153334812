import { useSelector } from 'react-redux';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice';
import { ReactComponent as Spinner } from '../../../SVGIcons/spinner.svg';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../utils/utils';
import { FEATURES } from '../../../utils/constants';

const FlexKeyValueComp = ({
  data,
  btn = false,
  onClaimClick,
  decimal = true,
}: {
  data: any[];
  btn?: boolean;
  onClaimClick?: any;
  decimal?: boolean;
}) => {
  const { claimRewardLoading } = useSelector(selectSuperNodeSlice);
  return (
    <div className="flex flex-col h-full gap-2 justify-evenly">
      {data?.length > 0 &&
        data?.map((item, index) => (
          <div key={index} className="flex items-end justify-between">
            <div className="flex items-center ">
              <div className="flex gap-1 text-xs xxs:gap-1 text-primaryGrey md:text-xs">
                <div className="flex flex-row items-center gap-2">
                  <span
                    className={` font-normal ${item.unit ? 'text-sm xxs:text-xs xs:text-sm  xl:text-xs 2xl:text-sm' : 'text-sm'}`}
                  >
                    {item.label}
                  </span>
                  {item.btn &&
                    (claimRewardLoading ? (
                      <div className="flex items-center justify-center flex-1 h-full text-primary">
                        <div className="items-center self-center justify-center">
                          <div className="flex flex-col gap-5">
                            <Spinner className="h-6 animate-spin" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        // disabled={
                        //   item.label.toLowerCase() === 'claimable rewards' &&
                        //   Number(item.value) <= 0
                        //     ? true
                        //     : false
                        // }
                        disabled={
                          FEATURES.enableClaimButton
                            ? Number(item.value) <= 0
                            : true
                        }
                        onClick={onClaimClick}
                        className={` h-[1.6rem] xs:h-[1.6rem] xxs:h-[1.2rem] xl:h-[1.2rem] 2xl:h-[1.6rem] xs:w-[4.6rem] w-[4.6rem] xxs:w-[2.5rem]  xl:w-[2.5rem]  2xl:w-[4.6rem] rounded  flex items-center justify-center text-white font-normal ${item.unit ? 'text-[10px]' : 'text-xs'} ${
                          FEATURES.enableClaimButton
                            ? item.label.toLowerCase() ===
                                'claimable rewards' && Number(item.value) <= 0
                              ? '!bg-primaryGrey/50 cursor-not-allowed'
                              : 'bg-gradient-primary-blue'
                            : '!bg-primaryGrey/50 cursor-not-allowed'
                          // : 'bg-gradient-primary-blue'
                        }`}
                      >
                        Claim
                      </button>
                    ))}
                </div>
              </div>
            </div>
            {item?.switch ? (
              item.switch
            ) : (
              <div className={`text-defaultBlack font-medium `}>
                <span
                  className={` ${item.bigText && typeof item.value === 'number' ? (item.unit ? 'text-3xl xl:text-xl 2xl:text-3xl' : 'text-3xl') : typeof item.value === 'number' ? (item.unit ? 'xl:text-base 2xl:text-lg text-lg ' : 'text-lg') : 'text-sm'}`}
                >
                  {!isNaN(item.value)
                    ? !decimal
                      ? item?.value
                      : item?.unit && item.value !== 0
                        ? formatNumberWithCommas(
                            Number(toFixedWithoutRoundingOff(item.value, 6))
                          )
                        : toFixedWithoutRoundingOff(item.value, 2)
                    : formatNumberWithCommas(item.value)}
                </span>
                {item.unit && typeof item.value === 'number' && (
                  <span className="text-[.6rem] ml-1">
                    {typeof item.value === 'number' ? item.unit : ''}
                  </span>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
export default FlexKeyValueComp;
