import { Stack } from '@mui/material';
import { Spinner } from '../../../../../images/svgs';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../../../utils/utils';
import { useSNGPPoolActions } from '../../../../../store/sngpPool/sngpPoolActions';
import { useSelector } from 'react-redux';
import { selectSNGPPoolSlice } from '../../../../../store/sngpPool/sngpPoolSlice';

const BannerComp = () => {
  const { claimSNGPPoolReward } = useSNGPPoolActions();
  const { claimLoading, sngpBannerData, sngpBannerLoading } =
    useSelector(selectSNGPPoolSlice);
  return (
    <div className="flex h-[180px] rounded w-full justify-between py-6 xxs:px-4 md:px-10 bg-sngpBg bg-cover bg-no-repeat bg-center">
      {sngpBannerLoading ? (
        <div className="flex items-center justify-center flex-1 h-full min-h-32">
          <div className="items-center self-center justify-center">
            <div className="flex flex-col gap-5">
              <Spinner className="h-6 animate-spin" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full gap-1 xxs:flex-col md:flex-row">
          <Stack
            direction={{ xs: 'column' }}
            spacing={{ xs: 0.5, md: 1 }}
            width={{ xs: '100%', md: '100%' }}
            padding={'4px'}
            border={'4px'}
          >
            <span className="text-2xl font-medium text-white md:text-3xl ">
              Total SNGP Rewards
            </span>
            <span className="text-2xl font-bold text-white md:text-4xl">
              {sngpBannerData && formatNumberWithCommas(sngpBannerData?.totalPoint)}{' '}
              {sngpBannerData && sngpBannerData?.rewardToken}
            </span>
            <span className="text-xl font-semibold text-white md:text-3xl">
              $
              {sngpBannerData && formatNumberWithCommas(
                Number(
                  toFixedWithoutRoundingOff(sngpBannerData?.totalRewardUSD, 2)
                ) || 0
              )}
            </span>
          </Stack>
          <div className="flex items-end flex-1 w-full mr-6">
            {claimLoading ? (
              <Spinner className="h-6 animate-spin" />
            ) : (
              <button
                onClick={() => {
                  if (!claimLoading) {
                    claimSNGPPoolReward();
                  }
                }}
                disabled={claimLoading}
                className="px-6 py-2 w-36 h-9 flex justify-center items-center rounded-lg bg-white text-[#1B9BD6] font-normal border border-[#1B9BD6] hover:bg-[#1B9BD6] hover:text-white transition-all shadow-sm"
              >
                Claim Now
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BannerComp;
