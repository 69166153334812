import React from 'react';
import HomepageAccordion from './HomepageAccordion';
import { starts, steps } from '../../../utils/data/dashboardSteps';
import {
  flag,
  toolkit,
  Access,
  Account,
  Apps,
  Defi360,
  Device,
  Interface,
  Plan,
  Power,
  Support,
  Web3,
  HomnifiWaterMark,
} from '../../../images/Dashboard';
import { selectDrawer } from '../../../store/drawer/drawerSlice';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const Steps = ({
  setStep,
  activeStep,
}: {
  setStep: (value: string) => void;
  activeStep: string;
}) => {
  const toggleDrawer = useSelector(selectDrawer);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const stepIcons = [
    { icon: Plan, style: 'w-[50px] lg:w-[65px] lg:ml-8' },
    { icon: Device, style: 'w-[90px] mr-4 lg:w-[75px]' },
    { icon: Apps, style: 'w-[65px]' },
    { icon: Account, style: 'w-[35px] md:w-[50px]' },
    { icon: Defi360, style: 'w-[65px]' },
  ];
  const startIcons = [
    { icon: Web3, style: 'w-[40px] md:w-[60px]' },
    { icon: Power, style: 'w-[40px] md:w-[60px]' },
    { icon: Access, style: 'w-[40px] md:w-[75px]' },
    { icon: Interface, style: 'w-[40px] md:w-[75px]' },
    { icon: Support, style: 'w-[30px] md:w-[50px]' },
  ];
  return (
    <section className="px-6  md:px-20 flex flex-col gap-8 w-full m-auto 2xl:w-[1200px] ">
      <div className="flex flex-row  w-full justify-between">
        <div className="flex flex-col w-full justify-center items-center">
          <button
            className={`w-full gradient-texts uppercase text-[1.3rem] min-[425px]:text-[2rem] min-[600px]:text-[3rem]  ${toggleDrawer && !isTablet ? 'md:text-[2.2rem]' : 'md:text-[3rem]'} ${toggleDrawer && !isTablet ? 'lg:text-[3.3rem]' : 'lg:text-[4.3rem]'} xl:text-[4.3rem]  font-bold items-center justify-center  flex gap-2 min-[425px]:gap-4 min-[500px]:gap-8  ${toggleDrawer && !isTablet ? 'md:gap-[1rem]' : 'md:gap-[2rem]'} ${toggleDrawer && !isTablet ? 'lg:gap-[30px]' : 'lg:gap-20'} xl:gap-20  !leading-6 md:!leading-10 mainGradient outline-none relative                
              `}
            onClick={() => setStep('start')}
            style={{ zIndex: 1 }}
          >
            <span
              className={`gradient-texts
                        ${activeStep === 'start' ? 'text-primary' : 'text-primaryGrey/50'}
                    `}
            >
              Start
            </span>
            <img
              src={flag}
              alt="Flag"
              className={`gradient-img ${activeStep === 'start' ? 'text-primary' : 'text-primaryGrey/50 saturate-0'} w-[29px] min-[425px]:w-[42px] md:w-[50px] lg:w-[71px] `}
            />
          </button>

          <div
            className={`.gradient-text flex justify-center items-center w-full h-[4px] ${activeStep === 'start' ? 'bg-primary' : 'bg-transparent '} mt-6  relative`}
            style={{ zIndex: 0 }}
          >
            <div
              className={`.gradient-text w-full h-[2px] ${activeStep === 'start' ? 'bg-primary' : 'bg-primaryGrey/50 '}   relative`}
              style={{ zIndex: 0 }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full justify-center items-center">
          <button
            className={`w-full gradient-texts uppercase text-[1.3rem]  min-[425px]:text-[2rem] min-[600px]:text-[3rem]  ${toggleDrawer && !isTablet ? 'md:text-[2.1rem]' : 'md:text-[3rem]'}  ${toggleDrawer && !isTablet ? 'lg:text-[3.3rem]' : 'lg:text-[4.3rem]'} xl:text-[4.3rem]  font-bold items-center justify-center   flex gap-2 min-[425px]:gap-4 min-[500px]:gap-8 ${toggleDrawer && !isTablet ? 'md:gap-[1rem]' : 'md:gap-[2rem]'} ${toggleDrawer && !isTablet ? 'lg:gap-[30px]' : 'lg:gap-20'} xl:gap-20  !leading-6 md:!leading-10 mainGradient relative             
              `}
            onClick={() => setStep('get')}
            style={{ zIndex: 1 }}
          >
            <span
              className={`gradient-texts ${activeStep === 'get' ? 'text-primary' : 'text-primaryGrey/50'}`}
            >
              Enjoy
            </span>
            <img
              src={toolkit}
              alt="Toolkit"
              className={`gradient-img ${activeStep === 'get' ? 'text-primary' : 'text-primaryGrey/50 saturate-0'} w-[44px] min-[425px]:w-[64px] md:w-[74px] lg:w-[106px] `}
            />
          </button>

          <div
            className={`.gradient-text flex justify-center items-center w-full h-[4px] ${activeStep === 'get' ? 'bg-primary' : 'bg-transparent '} mt-6  relative`}
            style={{ zIndex: 0 }}
          >
            <div
              className={`.gradient-text w-full h-[2px] ${activeStep === 'get' ? 'bg-primary' : 'bg-primaryGrey/50 '}   relative`}
              style={{ zIndex: 0 }}
            />
          </div>
        </div>
      </div>
      <article className="relative w-full lg:my-10">
        <HomepageAccordion
          data={activeStep === 'start' ? steps : starts}
          icons={activeStep === 'start' ? stepIcons : startIcons}
        />
        <img
          src={HomnifiWaterMark}
          alt="HomnifiWatermark"
          className="absolute -bottom-[3.5rem] -right-[2.5rem] md:-bottom-[4rem] md:-right-[3.5rem] lg:-bottom-[7rem] lg:-right-[2.5rem] xl:-bottom-[4.2rem] xl:right-[0.5rem] w-[200px] lg:w-[280px]  z-0"
        />
      </article>
    </section>
  );
};

export default Steps;
