import { useState } from 'react';
import { toast } from 'react-toastify';
import GlobalHeading from '../../components/Global/GlobalHeading';
import useUserCheck from '../../hooks/useUserCheck';
import { ReactComponent as InfoWhite } from '../../SVGIcons/InfoWhite.svg';
import { ReactComponent as CopyGrey } from '../../SVGIcons/copyGrey.svg';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';

const WalletHeadingSection = () => {
  const [isCopied, setIsCopied] = useState(false);
  const { user } = useUserCheck();

  const handleCopy = () => {
    writeClipboardText(user?.id || '');
    toast.success('ID Copied!');
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  const writeClipboardText = async (text: string) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
      } else {
        fallbackWriteClipboardText(text);
      }
    } catch (error) {
      fallbackWriteClipboardText(text);
    }
  };

  const fallbackWriteClipboardText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // For mobile devices

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error('Fallback copy to clipboard failed: ', error);
    }

    document.body.removeChild(textArea);
  };
  return (
    <div className="flex sm:items-center justify-between flex-col sm:flex-row md:flex-col md:items-start lg:items-center lg:flex-row gap-2 lg:space-y-0">
      <div className="">
        <GlobalHeading
          heading={`My Wallets`}
          className="flex-1 !text-defaultBlack w-max"
        />
      </div>
      <div className=" relative bg-gradient-primary-blue-bottom-to-top border justify-between   border-[#E6E6E6] rounded-xl  flex xxs:h-full   xxs:flex-col xs:flex-row">
        <div className=" flex  p-3 items-center gap-3 justify-evenly  rounded-xl text-white font-normal text-base ">
          <div className=" absolute left-[1px] xs:top-[16px] top-[35%]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="33"
              viewBox="0 0 2 33"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 33L2 -8.74227e-08C0.89543 -3.91405e-08 3.91405e-08 0.895431 8.74228e-08 2L1.35505e-06 31C1.40334e-06 32.1046 0.895431 33 2 33Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex  items-center">
            <span className="xxs:text-xs screen470:text-sm lg:text-sm xl:text-base">
              To request an internal token, please use the
              <strong> USER ID.</strong>
            </span>
          </div>
        </div>
        <div className=" flex  justify-center items-center p-3 ">
          <div className="px-2 py-1 flex items-center sm:gap-2 xxs:gap-5 bg-white rounded-md min-h-9  justify-center  ">
            {user?.id ? (
              <span className="xxs:text-base lg:text-lg xl:text-xl text-primaryGrey">
                {user?.id && user?.id}{' '}
              </span>
            ) : (
              <GlobalSkeleton
                animationValue="wave"
                width={100}
                height={24}
                variant="rectangular"
              />
            )}
            <div className=" w-[1px] bg-gray-300 h-9"></div>
            <div onClick={handleCopy} className="cursor-pointer">
              <CopyGrey />
            </div>
          </div>
        </div>

        <div className=" absolute right-[1px] xs:top-[16px] top-[35%]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="33"
            viewBox="0 0 2 33"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 33L2 -8.74227e-08C0.89543 -3.91405e-08 3.91405e-08 0.895431 8.74228e-08 2L1.35505e-06 31C1.40334e-06 32.1046 0.895431 33 2 33Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default WalletHeadingSection;
