import { mobileHardwareImgStep1 } from '../../../../../../images/cloudk';

const Step1 = ({ phoneMinter }: any) => {
  return (
    <div className="">
      <div className="w-full flex flex-col gap-2  items-center">
        <div className="w-full flex justify-center my-4">
          <img src={mobileHardwareImgStep1} alt="" className="h-48 sm:h-[350px]" />
        </div>
        <p className=" text-center">
          Open the Homnifi app on the phone with the serial number given below:{' '}
        </p>
        <span className="py-1 px-4 sm:py-1 sm:px-8  border rounded-md font-medium border-[#1B9FDA] bg-[#E0F7FE]">
          {phoneMinter?.identifier}
        </span>
      </div>
    </div>
  );
};

export default Step1;
