import GlobalSkeleton from '../../../components/Global/GlobalSkeleton';

const ListingMemberLoading = () => {
  return (
    <div className={`min-w-[1000px] pt-3`}>
      <div className="relative w-full ">
        <div className="pt-[5px] mx-[1.2rem] rounded-t-[.7rem]" />
      </div>
      <div className="bg-white border-[1px] mx-3 rounded-lg shadow-md font-normal text-base text-titlePrimary relative">
        <div className="flex gap-5 cursor-pointer">
          <div className="w-[280px] ml-5 flex items-center gap-2 py-2">
            <GlobalSkeleton
              variant="circular"
              animationValue="pulse"
              height={40}
              width={40}
            />
            <div className="flex flex-col text-base">
              <GlobalSkeleton animationValue="pulse" height={20} width={100} />
              <GlobalSkeleton animationValue="pulse" height={20} width={120} />
            </div>
          </div>

          <div className="flex-1 grid grid-cols-4 md:grid-cols-4 xs:grid-cols-4 gap-4 md:gap-8 items-center text-16px font-normal">
            {Array(5)
              .fill({})
              .map(
                () => (
                  <div className="flex flex-col items-center justify-center">
                    <GlobalSkeleton
                      animationValue="pulse"
                      height={20}
                      width={60}
                    />
                    <GlobalSkeleton
                      animationValue="pulse"
                      height={20}
                      width={80}
                    />
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingMemberLoading;
