import React from 'react';
import { productImage, worksImage } from '../../../images/Dashboard';
import { PLATFORM_NAME } from '../../../utils/constants';

const MembershipPurchased = () => {
  return (
    <div className="flex flex-col gap-4 w-full 2xl:w-[1200px] m-auto sm:px-10 px-5">
      <div className="flex flex-col lg:flex-row justify-between gap-4 w-full">
        <div className="flex flex-col items-center w-full sm:text-[70px] text-[40px] font-bold uppercase">
          <div className="active-gradient-text">homnifi </div>
          <div className="active-gradient-text">platform</div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-baumans text-2xl">
            {PLATFORM_NAME} is a revolutionary platform that opens the door to
            the world of Web3 and decentralized applications (dApps)
          </div>
          <div className="text-base">
            By providing a comprehensive suite of dedicated applications (apps),
            dApps, and innovative hardware solutions. <br />
            {PLATFORM_NAME} simplifies the transition into the digital future.
            Whether you're a novice exploring blockchain technology or an
            advanced user looking to maximize your Web3 capabilities.
          </div>
        </div>
      </div>
      <div className="px-6 py-4 w-full bg-gradient-to-r from-primary to-primaryLight rounded-lg flex flex-col md:flex-row gap-4 justify-between items-center text-white">
        <div className="flex flex-col gap-4 w-full">
          <div className="font-baumans text-2xl">
            {PLATFORM_NAME} offers a seamless and integrated experience.
          </div>
          <div>
            Additionally, with the Defi360 concept, users can access the entire
            decentralized finance (DeFi) ecosystems through a single, unified
            project, making engagement with these technologies more accessible
            than ever.
          </div>
        </div>
        <div className="w-full flex justify-center">
          <img src={productImage} alt="Product" className="w-72" />
        </div>
      </div>
      <div className="flex flex-col items-center w-full text-[30px] sm:text-[40px] md:text-[60px] lg:text-[70px] font-bold uppercase active-gradient-text">
        How it works
      </div>
      <div className="flex flex-col-reverse md:flex-row w-full gap-8">
        <div className="flex flex-col gap-8 h-full">
          <div className="w-full border border-primary rounded-lg p-4 bg-primary/20">
            <div className="flex flex-col gap-8">
              <div className="text-2xl font-baumans">Hardware</div>
              <div>
                {PLATFORM_NAME} offers hardware devices that users can install
                at home. These devices act as a gateway to Web3, enhancing your
                computational power and abilities.
              </div>
              <div>
                They also support your token development capabilities, making
                the complex processes of Web3 simple and accessible.
              </div>
            </div>
          </div>
          <div className="w-full border border-primary rounded-lg p-4 bg-primary/20">
            <div className="flex flex-col gap-8">
              <div className="text-2xl font-baumans">
                Dedicated Apps and dApps
              </div>
              <div>
                Users gain access to a suite of dedicated apps and dApps
                designed to simplify and optimize their interaction with the
                Web3 ecosystem.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 h-full w-full">
          <div className="w-full p-4 flex justify-center">
            <img src={worksImage} alt="Works" className="w-52 h-full" />
          </div>
          <div className="w-full border border-primary rounded-lg p-4 bg-primary/20">
            <div className="flex flex-col gap-8">
              <div className="text-2xl font-baumans">Defi360 Integration</div>
              <div>
                With {PLATFORM_NAME}, you also get access to Defi360, a
                comprehensive concept that allows you to access various DeFi
                ecosystems through a single project.
              </div>
              <div>
                This means you can engage with different decentralized
                applications, including lending, borrowing, trading, and
                staking, all from one platform.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipPurchased;
