export const DashboardBannerImage = '/img/Dashboard/DashboardBannerImage.png';
export const DashboardCardBg = '/img/Dashboard/horysmall.png';
export const DigiBridgeCardBg = '/img/Dashboard/DigiBridge.png';
export const QuantwiseCardBg = '/img/Dashboard/Quantwise.png';
export const CloudKCardBg = '/img/Dashboard/cloudk.png';
export const PaymentCard = '/img/Dashboard/card.png';
export const RampStarterCardBg = '/img/Dashboard/rampstarter.png';
export const WalleKCardBg = '/img/Dashboard/wallek.png';
export const MachineXk5000 = '/img/Dashboard/XK5000.png';
export const banner = '/img/Dashboard/banner.png';
export const banner2 = '/img/Dashboard/banner2.png';
export const productImage = '/img/Dashboard/product.png';
export const worksImage = '/img/Dashboard/works.png';
export const NoDataImage = '/img/NoDataFound.png';
export const flag = '/img/Dashboard/flag.png';
export const toolkit = '/img/Dashboard/toolkit.png';
export const light = '/img/Dashboard/light.png';
export const Plan = '/img/Dashboard/start/plan.png';
export const Device = '/img/Dashboard/start/device.png';
export const Apps = '/img/Dashboard/start/apps.png';
export const Account = '/img/Dashboard/start/account.png';
export const Defi360 = '/img/Dashboard/start/defi360.png';
export const Web3 = '/img/Dashboard/enjoy/web3.png';
export const Power = '/img/Dashboard/enjoy/power.png';
export const Access = '/img/Dashboard/enjoy/access.png';
export const Interface = '/img/Dashboard/enjoy/interface.png';
export const Support = '/img/Dashboard/enjoy/support.png';
export const HomnifiWaterMark = '/img/Dashboard/HomnifiWatermark.png';
