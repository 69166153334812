import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { securityIcon } from '../../../../images/wallets';

const GenerateAddressInfo: React.FC<{
  handleClick: () => void;
  handleClickOutisde: () => void;
  show: boolean;
}> = ({ handleClick, handleClickOutisde, show }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        handleClickOutisde(); // Close the popup
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutisde]);

  useEffect(() => {
    if (show) {
      controls.start({ opacity: 1, scale: 1 });
    } else {
      controls.start({ opacity: 0, scale: 0.95 });
    }
  }, [show, controls]);

  return (
    <motion.div
      ref={popupRef}
      initial={{ opacity: 0, scale: 0.95 }}
      animate={controls}
      transition={{ duration: 0.3, ease: 'easeIn' }}
      className="flex flex-col absolute top-0 bg-white gap-4 md:ml-10 md:w-[305px] z-[9999] items-center border-[1px] border-[#1B9FDA80] rounded-lg shadow-[0px_6px_6px_0px_#1B9FDA1F] px-4 py-4"
    >
      <img src={securityIcon} height={52} width={52} alt="Security Icon" />
      <span className="text-[#7D7D7D] text-[12px] font-normal leading-[14.22px] text-center">
        This is a one-time address and should not be saved for future deposits.
      </span>
      <hr className="w-[90%] h-0 border-t border-[#D9E4F1]" />
      <div className="w-full flex justify-end">
        <button
          onClick={handleClick}
          className="py-[8px] px-[20px] text-[#FFFFFF] rounded-md border-[0.5px] border-[#1B9FDA] bg-[#1B9FDA] text-[12px] font-normal leading-[14.22px] text-center shadow-[0px_2px_0px_#007BB2] w-fit transition-all duration-200 hover:shadow-[0px_4px_4px_#007BB2] cursor-pointer"
        >
          I understand
        </button>
      </div>
    </motion.div>
  );
};

export default GenerateAddressInfo;
