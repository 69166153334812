import {
  TChartDataDefs,
  TChartDataFill,
  TChartDataType,
  TSkillamiPointsFinanceDashboardData,
  TSkillamiPointsFinanceDashboardDefs,
  TSkillamiPointsFinanceDashboardFill,
} from '../../components/types/Charts/lineChart.types';

export const GskCardDashboardSuperNodeData: TSkillamiPointsFinanceDashboardData =
  [
    {
      id: 'Skillami Points',
      data: [
        {
          x: 'January',
          y: 550,
        },
        {
          x: 'Feb',
          y: 700,
        },
        {
          x: 'Mar',
          y: 650,
        },
        {
          x: 'Apr',
          y: 850,
        },
        {
          x: 'May',
          y: 800,
        },
        {
          x: 'June',
          y: 900,
        },
        {
          x: 'July',
          y: 1100,
        },
        {
          x: 'Aug',
          y: 1000,
        },
        {
          x: 'Sep',
          y: 1200,
        },
        {
          x: 'Oct',
          y: 900,
        },
        {
          x: 'Nov',
          y: 950,
        },
        {
          x: 'Dec',
          y: 1200,
        },
      ],
    },
  ];

export const GskCardDashboardSuperNodeDefs: TSkillamiPointsFinanceDashboardDefs =
  [
    {
      id: 'gradient1',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#FFFFFF40' },
        {
          offset: 70,
          color: '#FFFFFF40',
        },
      ],
    },
  ];

export const GskCardDashboardSuperNodeFill: TSkillamiPointsFinanceDashboardFill =
  [{ match: { id: 'Skillami Points' }, id: 'gradient1' }];

export const GaskCardDashboardSuperNodeData: TChartDataType = [
  {
    id: 'GASK',
    data: [
      {
        x: 'January',
        y: 420,
      },
      {
        x: 'Feb',
        y: 118,
      },
      {
        x: 'Mar',
        y: 300,
      },
      {
        x: 'Apr',
        y: 127,
      },
      {
        x: 'May',
        y: 333,
      },
      {
        x: 'June',
        y: 444,
      },
      {
        x: 'July',
        y: 700,
      },
      {
        x: 'Aug',
        y: 555,
      },
      {
        x: 'Sep',
        y: 700,
      },
      {
        x: 'Oct',
        y: 1600,
      },
      {
        x: 'Nov',
        y: 1106,
      },
      {
        x: 'Dec',
        y: 1200,
      },
    ],
  },
  {
    id: 'Lost Rewards',
    data: [
      {
        x: 'January',
        y: 400,
      },
      {
        x: 'Feb',
        y: 500,
      },
      {
        x: 'Mar',
        y: 600,
      },
      {
        x: 'Apr',
        y: 450,
      },
      {
        x: 'May',
        y: 500,
      },
      {
        x: 'June',
        y: 600,
      },
      {
        x: 'July',
        y: 700,
      },
      {
        x: 'Aug',
        y: 600,
      },
      {
        x: 'Sep',
        y: 700,
      },
      {
        x: 'Oct',
        y: 1000,
      },
      {
        x: 'Nov',
        y: 1100,
      },
      {
        x: 'Dec',
        y: 1200,
      },
    ],
  },
  {
    id: 'Total Received',
    data: [
      {
        x: 'January',
        y: 550,
      },
      {
        x: 'Feb',
        y: 700,
      },
      {
        x: 'Mar',
        y: 650,
      },
      {
        x: 'Apr',
        y: 850,
      },
      {
        x: 'May',
        y: 800,
      },
      {
        x: 'June',
        y: 900,
      },
      {
        x: 'July',
        y: 1100,
      },
      {
        x: 'Aug',
        y: 1000,
      },
      {
        x: 'Sep',
        y: 1200,
      },
      {
        x: 'Oct',
        y: 900,
      },
      {
        x: 'Nov',
        y: 950,
      },
      {
        x: 'Dec',
        y: 1200,
      },
    ],
  },
];

export const GaskCardDashboardSuperNodeDefs: TChartDataDefs = [
  {
    id: 'gradient1',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: '#F2F4F7' },
      {
        offset: 70,
        color: '#F2F4F7',
      },
    ],
  },
  {
    id: 'gradient2',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: '#F2F4F7' },
      {
        offset: 70,
        color: '#F2F4F7',
      },
    ],
  },
  {
    id: 'gradient3',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: '#F2F4F7' },
      {
        offset: 70,
        color: '#F2F4F7',
      },
    ],
  },
];

export const GaskCardDashboardSuperNodeFill: TChartDataFill = [
  { match: { id: 'Total Received' }, id: 'gradient1' },
  { match: { id: 'Lost Rewards' }, id: 'gradient2' },
  { match: { id: 'GASK' }, id: 'gradient3' },
];

export const TotalRewardsChartData: TChartDataType = [
  {
    id: 'Rewards',
    data: [
      {
        x: 'January',
        y: 420,
      },
      {
        x: 'Feb',
        y: 118,
      },
      {
        x: 'Mar',
        y: 300,
      },
      {
        x: 'Apr',
        y: 127,
      },
      {
        x: 'May',
        y: 333,
      },
      {
        x: 'June',
        y: 444,
      },
      {
        x: 'July',
        y: 700,
      },
      {
        x: 'Aug',
        y: 555,
      },
      {
        x: 'Sep',
        y: 700,
      },
      {
        x: 'Oct',
        y: 1600,
      },
      {
        x: 'Nov',
        y: 1106,
      },
      {
        x: 'Dec',
        y: 1200,
      },
    ],
  },
];

export const TotalRewardsChartDefs: TChartDataDefs = [
  {
    id: 'gradient1',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: '#0E173F' },
      { offset: 100, color: '#FFFFFF' },
    ],
  },
];

export const TotalRewardsChartFill: TChartDataFill = [
  { match: '*', id: 'gradient1' },
];

export const RewardsCalculatorChartData: TChartDataType = [
  {
    id: 'Rewards',
    color: 'hsl(226, 70%, 50%)',
    data: [
      {
        x: '2 M',
        y: 420,
      },
      {
        x: '4 M',
        y: 118,
      },
      {
        x: '6 M',
        y: 300,
      },
      {
        x: '8 M',
        y: 127,
      },
      {
        x: '10 M',
        y: 333,
      },
      {
        x: '12 M',
        y: 444,
      },
      {
        x: '14 M',
        y: 700,
      },
      {
        x: '16 M',
        y: 555,
      },
      {
        x: '18 M',
        y: 700,
      },
      {
        x: '20 M',
        y: 1600,
      },
      {
        x: '22 M',
        y: 1106,
      },
      {
        x: '24 M',
        y: 1200,
      },
    ],
  },
  {
    id: 'LYK Price',
    data: [
      {
        x: '2 M',
        y: 420,
      },
      {
        x: '4 M',
        y: 118,
      },
      {
        x: '6 M',
        y: 300,
      },
      {
        x: '8 M',
        y: 127,
      },
      {
        x: '10 M',
        y: 333,
      },
      {
        x: '12 M',
        y: 444,
      },
      {
        x: '14 M',
        y: 700,
      },
      {
        x: '16 M',
        y: 555,
      },
      {
        x: '18 M',
        y: 700,
      },
      {
        x: '20 M',
        y: 1600,
      },
      {
        x: '22 M',
        y: 1106,
      },
      {
        x: '24 M',
        y: 1200,
      },
    ],
  },
  {
    id: 'DLP',
    data: [
      {
        x: '2 M',
        y: 420,
      },
      {
        x: '4 M',
        y: 118,
      },
      {
        x: '6 M',
        y: 300,
      },
      {
        x: '8 M',
        y: 127,
      },
      {
        x: '10 M',
        y: 333,
      },
      {
        x: '12 M',
        y: 444,
      },
      {
        x: '14 M',
        y: 700,
      },
      {
        x: '16 M',
        y: 555,
      },
      {
        x: '18 M',
        y: 700,
      },
      {
        x: '20 M',
        y: 1600,
      },
      {
        x: '22 M',
        y: 1106,
      },
      {
        x: '24 M',
        y: 1200,
      },
    ],
  },
  {
    id: 'ATH',
    color: 'hsl(161, 70%, 50%)',
    data: [
      {
        x: '2 M',
        y: 420,
      },
      {
        x: '4 M',
        y: 118,
      },
      {
        x: '6 M',
        y: 300,
      },
      {
        x: '8 M',
        y: 127,
      },
      {
        x: '10 M',
        y: 333,
      },
      {
        x: '12 M',
        y: 444,
      },
      {
        x: '14 M',
        y: 700,
      },
      {
        x: '16 M',
        y: 555,
      },
      {
        x: '18 M',
        y: 700,
      },
      {
        x: '20 M',
        y: 1600,
      },
      {
        x: '22 M',
        y: 1106,
      },
      {
        x: '24 M',
        y: 1200,
      },
    ],
  },
];

export const RewardsCalculatorChartDefs: TChartDataDefs = [
  {
    id: 'gradient1',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: '#F2F4F7' },
      {
        offset: 70,
        color: '#F2F4F7',
      },
    ],
  },
];

export const RewardsCalculatorChartFill: TChartDataFill = [
  { match: { id: 'Rewards' }, id: 'gradient1' },
  { match: { id: 'LYK Price' }, id: 'gradient1' },
  { match: { id: 'DLP' }, id: 'gradient1' },
  { match: { id: 'ATH' }, id: 'gradient1' },
];
