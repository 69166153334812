import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSuperNodeSlice } from '../../../../store/superNode/superNodeSlice';
import DataLoader from '../../../../components/DataLoader/DataLoader';

interface IStep {
  label: string;
  unit?: number;
  isHeading?: boolean;
}

const VerticalStepper: React.FC<{ steps: IStep[] }> = ({
  steps,
}): ReactElement => {
  const { getMyTreeLoading } = useSelector(selectSuperNodeSlice);

  return (
    <div className="flex flex-col items-start mt-4">
      {steps.length > 0 &&
        steps?.map(
          (step: IStep, index: number): ReactElement => (
            <div key={index} className="flex items-start w-full">
              <div className="flex flex-col items-center">
                <div className="w-2 h-2 bg-gray-300 rounded-full" />
                {index < steps.length - 1 && (
                  <div className="w-px h-6 bg-gray-300"></div>
                )}
              </div>
              <div className="flex items-center justify-between w-full ml-4 -mt-2">
                <span
                  className={`text-sm text-gray-500 ${step?.isHeading && step?.isHeading ? 'font-medium' : 'font-normal'}`}
                >
                  {step?.label}
                </span>
                {!step?.isHeading && (
                  <>
                    {' '}
                    {getMyTreeLoading ? (
                      <DataLoader />
                    ) : (
                      <span
                        className={`text-cardPrimaryText text-sm  ${step?.isHeading && typeof step?.unit === 'number' ? (step?.unit ? 'text-3xl xl:text-xl 2xl:text-3xl' : 'text-3xl') : typeof step?.unit === 'number' ? (step?.unit ? 'xl:text-base 2xl:text-lg text-lg ' : 'text-lg') : 'text-sm'}`}
                      >
                        {step?.unit && step?.unit}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          )
        )}
    </div>
  );
};

const MyTree = (): ReactElement => {
  const { myTreeData, getMyTreeLoading } = useSelector(selectSuperNodeSlice);

  const step2Data: IStep[] = [
    { label: 'Total Active Users', isHeading: true },
    {
      label: 'Base Referral',
      unit: myTreeData?.activeBaseReferralTotalNode || 0,
    },
    {
      label: 'Builder Generational',
      unit: myTreeData?.activeBuilderGenerationTotalNode || 0,
    },
  ];

  const stepData: IStep[] = [
    { label: 'No. of Active Users in First Line', isHeading: true },
    {
      label: 'Base Referral',
      unit: myTreeData?.activeFirstLineBaseReferralNode || 0,
    },
    {
      label: 'Builder Generational',
      unit: myTreeData?.activeFirstLineBuilderGenerationNode || 0,
    },
  ];

  return (
    <div className="flex flex-col h-full gap-2 justify-evenly">
      <div className="flex items-center justify-between w-full mt-2 ">
        <span className={`text-sm text-gray-500 font-normal`}>
          {'Total Nodes:'}
        </span>
        {getMyTreeLoading ? (
          <DataLoader />
        ) : (
          <span className={'text-3xl xl:text-xl 2xl:text-3xl font-semibold'}>
            {myTreeData?.totalNodes || 0}
          </span>
        )}
      </div>
      <div className="flex items-center justify-between w-full ">
        <span className={`text-sm text-gray-500 font-normal`}>
          {'No. of nodes in 1st line'}
        </span>
        {getMyTreeLoading ? (
          <DataLoader />
        ) : (
          <span className={'text-lg'}>{myTreeData?.firstLineNode || 0}</span>
        )}
      </div>
      <VerticalStepper steps={step2Data} />
      <VerticalStepper steps={stepData} />
    </div>
  );
};
export default MyTree;
