
const GlobalHeading = ({
  heading,
  className,
}: {
  heading: string;
  className?: string;
}) => {
  return (
    <div
      className={`font-semibold text-[24px] text-white bg-transparent font-baumans ${className}`}
    >
      {heading}
    </div>
  );
};

export default GlobalHeading;
