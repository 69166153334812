import { FC } from "react";
import { formatNumberWithCommas } from "../../../../../utils/utils";
import GlobalSkeleton from "../../../../../components/Global/GlobalSkeleton";

type TotalSNGPCardViewProps = {
    title: string,
    totalSNGP: number;
    loading: boolean;
}
const TotalSNGPCard: FC<TotalSNGPCardViewProps> = ({ title, totalSNGP, loading }) => {

    return (
        <div className="flex justify-between md:gap-2 gap-5 items-center text-primary w-full md:w-full ">
                <span className="text-sm md:text-sm font-normal w-full leading-none ">
                    {title}
                </span>
                <div className="flex items-center">
                    <span className="font-medium text-xl leading-none">
                        {formatNumberWithCommas(totalSNGP || 0)}
                    </span>
                </div>
            </div>
    );
}

export default TotalSNGPCard;