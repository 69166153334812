/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AnimatedPlatforms from '../../components/ExternApps/AnimatedPlatforms';
import CardSwiper from '../../components/ExternApps/CardSwiper';
import FeatureProducts from '../../components/ExternApps/FeatureProducts';
import PlatformContainer from '../../components/ExternApps/PlatformContainer';
import { usePlatformAction } from '../../store/platform/platformAction';
import { selectPlatorfom } from '../../store/platform/platformSlice';

const ExternalApp = () => {
  const {
    getFeaturedPlatformList,
    getFavoritePlatformList,
    setFavorite,
    platformLogin,
    getAdsList,
  } = usePlatformAction();

  const {
    featuredPlatform,
    favoritePlatform,
    platformLoginLoading,
    favoritePlatformLoading,
    featuredPlatformLoading,
    adsList,
    adsLoading,
  } = useSelector(selectPlatorfom);

  const [loadingCardId, setLoadingCardId] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleFavouriteClick = async (id: any, isFvt: any) => {
    await setFavorite(id, isFvt);
  };

  const handleLogin = async (id: string) => {
    setLoadingCardId(id);
    if (!platformLoginLoading) {
      const res = await platformLogin(id);

      setLoadingCardId('id');
      if (res?.status === 200) {
        window.open(
          res?.data?.redirectUri,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }
  };

  const fetchFeaturedPlatformList = async () => {
    if (!featuredPlatformLoading) {
      await getFeaturedPlatformList(true);
    }
  };

  const fetchFavoritePlatformList = async () => {
    if (!favoritePlatformLoading) {
      await getFavoritePlatformList(true);
    }
  };

  const fetchAdsList = async () => {
    if (!adsLoading) {
      await getAdsList(true);
    }
  };

  useEffect(() => {
    if (!featuredPlatform) {
      fetchFeaturedPlatformList();
    }
    if (!favoritePlatform) {
      fetchFavoritePlatformList();
    }
    if (!adsList) {
      fetchAdsList();
    }
  }, []);

  useEffect(() => {
    if (featuredPlatform) {
      const data = featuredPlatform?.filter(
        (item: any) =>
          item?.comingSoon === false && item?.isDisabledForUser === false
      );
      setFilteredData(data);
    }
  }, [featuredPlatform]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col items-center gap-10 py-4 xl:flex-row lg:justify-center">
        <div className={`w-full xl:${!adsList ? 'w-full' : 'w-2/3'}  h-full`}>
          {/* <AppsRewardCard cards1={card1} cards2={card1} cards3={card1} /> */}
          <AnimatedPlatforms
            cards1={filteredData}
            cards2={filteredData}
            cards3={filteredData}
            loginClick={(id) => handleLogin(id)}
          />
        </div>
        {adsList?.length > 0 && (
          <div className="w-full h-full xl:w-1/3">
            <CardSwiper />
          </div>
        )}
      </div>

      <FeatureProducts
        isPlatorms={true}
        platforms={favoritePlatform}
        onClick={(id, isFvt) => handleFavouriteClick(id, isFvt)}
        loginClick={(id) => handleLogin(id)}
        loginLoading={platformLoginLoading}
        loadingCardId={loadingCardId}
        loading={favoritePlatformLoading}
      />
      <FeatureProducts
        isPlatorms={false}
        platforms={featuredPlatform}
        onClick={(id, isFvt) => handleFavouriteClick(id, isFvt)}
        loginClick={(id) => handleLogin(id)}
        loginLoading={platformLoginLoading}
        loadingCardId={loadingCardId}
        loading={featuredPlatformLoading}
      />
      <PlatformContainer />
    </div>
  );
};

export default ExternalApp;
