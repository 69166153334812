import {
  CLOUDK_TRANSACTIONS,
  USER_GLOBAL_CLAIM_REWARDS,
  USER_GLOBAL_MACHINE_AUTO_COMPOUND,
  USER_PRODUCTS,
  USER_STAKE_INTO_MACHINE,
  ACTIVE_BURN,
  JOIN_BURN,
  BURN_USER_STATUS,
  BURN_TOKEN,
  BURN_WALLET_BALANCE,
  BURN_CALCULATION,
  CALCULATED_BURN,
  BURN_LIMIT,
  USER_PURCHASED_MACHINES,
  CLAIMABLE_REWARDS,
  LIFETIME_REWARDS,
} from './../../utils/network/ApiEndpoints';
import { useDispatch } from 'react-redux';
import {
  USER_GLOBAL_AUTO_COMPOUND,
  USER_MACHINES,
  USER_MACHINES_BY_ID,
  USER_REWARDS,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  setActiveBurn,
  setCalculatedBurn,
  setBurnUserStatus,
  setIsCalculatedBurnFailed,
  setBurnWalletBalance,
  setClouldk2Slice,
  setBurnLimit,
  setMachineDetails,
} from './clouldk2Slice';
import { toast } from 'react-toastify';
import { store } from '..';
import {
  TCloudSetAutoCompoundRequest,
  TCloudSetGlobalMachineAutoCompound,
  TPostUserGlobalClaimRewards,
} from '../../types/cloundk.type';
import { INVALID_TOKEN, UNAUTHORIZED } from '../../utils/constants';

export const useCloudActions = () => {
  const dispatch = useDispatch();

  const getUserRewardsData = async () => {
    dispatch(
      setClouldk2Slice({
        userRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_REWARDS,
      })
      .then((response) => {
        dispatch(
          setClouldk2Slice({
            userRewards: response.data.data,
            userRewardsLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getClaimableReward = async () => {
    dispatch(
      setClouldk2Slice({
        claimableRewardLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: CLAIMABLE_REWARDS,
      })
      .then((response) => {
        dispatch(
          setClouldk2Slice({
            claimableRewards: response?.data?.data,
            claimableRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            claimableRewardLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getLifetimeReward = async () => {
    dispatch(
      setClouldk2Slice({
        lifetimeRewardLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: LIFETIME_REWARDS,
      })
      .then((response) => {
        dispatch(
          setClouldk2Slice({
            lifetimeRewards: response.data.data,
            lifetimeRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            lifetimeRewardLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getUserMachinesList = async (
    loading = true,
    doNotShowExpired = 'no',
    doNotShowLimitExceeded = 'no'
  ) => {
    if (loading) {
      dispatch(
        setClouldk2Slice({
          userMachinesLoading: true,
        })
      );
    }

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_MACHINES,
        params: {
          doNotShowExpired,
          doNotShowLimitExceeded,
        },
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            machineList: [...response.data.data],
          })
        );

        if (loading) {
          dispatch(
            setClouldk2Slice({
              userMachinesLoading: false,
            })
          );
        }

        return response;
      })
      .catch((error) => {
        if (loading) {
          dispatch(
            setClouldk2Slice({
              userMachinesLoading: false,
            })
          );
        }

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getUserPurchasedMachines = async () => {
    dispatch(
      setClouldk2Slice({
        userPurchasedMachinesLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_PURCHASED_MACHINES,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            machinePurchased: response.data.data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            userPurchasedMachinesLoading: false,
          })
        );
      });
  };

  const getUserMachineById = async ({ id }: { id: string }) => {
    dispatch(
      setClouldk2Slice({
        userMachineByIdLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${USER_MACHINES_BY_ID}?machine=${id}`,
        data: id,
      })
      .then((response: any) => {
        const machine = store
          .getState()
          .clouldk2.machineList.map((_machine: any) =>
            _machine._id === id
              ? {
                  ..._machine,
                  ...response.data.data,
                }
              : _machine
          );

        dispatch(
          setClouldk2Slice({
            machineList: machine,
            userMachineByIdLoading: false,
          })
        );
        dispatch(setMachineDetails(response.data.data));

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userMachineByIdLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getGlobalAutoCompound = async () => {
    dispatch(
      setClouldk2Slice({
        userGlobalAutoCompoundLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_GLOBAL_AUTO_COMPOUND,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            globalAutoCompound: response.data.data.enabled,
          })
        );
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const setGlobalAutoCompound = async (data: TCloudSetAutoCompoundRequest) => {
    dispatch(
      setClouldk2Slice({
        userGlobalAutoCompoundLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_GLOBAL_AUTO_COMPOUND,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            globalAutoCompound: data.enabled,
          })
        );
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const setGlobalMachineAutoCompound = async (
    data: TCloudSetGlobalMachineAutoCompound
  ) => {
    dispatch(
      setClouldk2Slice({
        setGlobalMachineAutoCompoundLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_GLOBAL_MACHINE_AUTO_COMPOUND,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            setGlobalMachineAutoCompoundLoading: false,
          })
        );

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            setGlobalMachineAutoCompoundLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const postUserGlobalClaimRewards = async (
    data: TPostUserGlobalClaimRewards
  ) => {
    dispatch(
      setClouldk2Slice({
        userGlobalClaimRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_GLOBAL_CLAIM_REWARDS,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );

        if (response.data.data === 'Nothing to claim')
          toast.info(response.data.data);

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data);
        }
        return error;
      });
  };

  const getUserMachineRewards = async ({
    limit,
    page,
    storeRedux = true,
    search,
    token,
    machine,
    date,
    type,
    fromDate,
    toDate,
  }: {
    limit: number;
    page: number;
    storeRedux?: boolean;
    search?: string;
    token?: string;
    date?: string;
    type?: string;
    machine?: string;
    fromDate?: any;
    toDate?: any;
  }) => {
    dispatch(
      setClouldk2Slice({
        userTransactionHistoryLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: CLOUDK_TRANSACTIONS,
        params: {
          limit,
          page,
          search,
          token,
          type,
          machine,
          fromDate,
          toDate,
        },
      })
      .then((response: any) => {
        if (storeRedux) {
          dispatch(
            setClouldk2Slice({
              userTransactionHistory: response.data.data.list,
            })
          );
        }
        dispatch(
          setClouldk2Slice({
            userTransactionHistoryLoading: false,
          })
        );

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            userTransactionHistoryLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data);
        }
        return error;
      });
  };

  const postStakeIntoMachine = async (data: any) => {
    dispatch(
      setClouldk2Slice({
        userGlobalClaimRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_STAKE_INTO_MACHINE,
        data: { ...data },
      })
      .then(async (response: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );
        //424.47438385
        toast.success('success');

        setTimeout(async () => {
          await getUserMachinesList();
          await getUserRewardsData();
        }, 2000);

        dispatch(
          setCalculatedBurn({
            status: false,
            lykPrice: '',
            percentageToBeApplied: 0,
            usdKPromoWalletData: {
              amount: 0,
              dollarValue: 0,
              walletBalance: 0,
            },
            phaseWalletData: {
              amount: 0,
              dollarValue: 0,
            },
            stakeWalletData: {
              amount: 0,
              dollarValue: 0,
            },
            totalToBeStakeInMachineData: {
              totalAmount: 0,
              totalToken: 0,
              machinePrice: 0,
            },
            machine: {
              totalStaked: 0,
              totalMlykStaked: 0,
              mlykStakedAfterJoined: 0,
              smlykStakedAfterJoined: 0,
              maxStakeLimit: 0,
              boostPercentage: 0,
              boostPercentageRequiredInMlyk: 0,
              boostPercentageRequiredInMlykDoller: 0,
              normalPercentage: 0,
            },
            totalBurnAmount: {
              amount: 0,
              dollarValue: 0,
            },
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;

        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      });
  };

  const getAllProducts = async () => {
    dispatch(
      setClouldk2Slice({
        getAllProductsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_PRODUCTS,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            userProducts: response.data.data.list,
          })
        );
        dispatch(
          setClouldk2Slice({
            getAllProductsLoading: false,
          })
        );

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            getAllProductsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data);
        }
        return error;
      });
  };

  const getActiveBurn = async () => {
    dispatch(
      setClouldk2Slice({
        isBurnLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: ACTIVE_BURN,
      })
      .then((response: any) => {
        const { data } = response?.data;

        dispatch(setActiveBurn(data));

        return data;
      })
      .catch((error: any) => {
        if (error.data) {
          toast.error(error.data);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
      });
  };

  const getBurnCalculation = async ({
    machineId,
    amountToStake = 1,
    price,
  }: {
    machineId: string | any;
    amountToStake: number;
    price: number;
  }) => {
    dispatch(
      setClouldk2Slice({
        isCalculatedBurnLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${CALCULATED_BURN}?machine=${machineId}&amount=${amountToStake ? amountToStake : 0}&price=${price}`,
      })
      .then((response: any) => {
        const { data } = response?.data;
        dispatch(setCalculatedBurn(data));
        dispatch(setIsCalculatedBurnFailed(false));
        return data;
      })
      .catch((error: any) => {
        if (error.data) {
          dispatch(setIsCalculatedBurnFailed(true));
          toast.error(error.data.message);
          // dispatch(
          //   setCalculatedBurn({
          //     status: false,
          //     phaseWalletData: {
          //       amount: 0,
          //       dollarValue: 0,
          //       percentageToBeApplied: 0,
          //       walletBalance: 0,
          //     },
          //     stakeWalletData: {
          //       amount: 0,
          //       dollarValue: 0,
          //     },
          //     totalToBeStakeInMachineData: {
          //       totalAmount: 0,
          //       totalToken: 0,
          //       machinePrice: 0,
          //     },
          //     machine: {
          //       totalStaked: 0,
          //       totalMlykStaked: 0,
          //       mlykStakedAfterJoined: 0,
          //       smlykStakedAfterJoined: 0,
          //       maxStakeLimit: 0,
          //       boostPercentage: 0,
          //       boostPercentageRequiredInMlyk: 0,
          //       boostPercentageRequiredInMlykDoller: 0,
          //     },
          //   })
          // );
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isCalculatedBurnLoading: false,
          })
        );
      });
  };

  const getBurnLimit = async ({
    machineId,
    price,
  }: {
    machineId: string | any;
    price: number;
  }) => {
    dispatch(
      setClouldk2Slice({
        isBurnLimitLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${BURN_LIMIT}`,
        params: {
          machine: machineId,
          price: price,
        },
      })
      .then((response: any) => {
        const { data } = response?.data;
        dispatch(setBurnLimit(data));
        return data;
      })
      .catch((error: any) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isBurnLimitLoading: false,
          })
        );
      });
  };

  const postJoinBurnCompaign = async () => {
    dispatch(
      setClouldk2Slice({
        isBurnLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: JOIN_BURN,
      })
      .then((response: any) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
      });
  };

  const getBurnUserStatus = async () => {
    dispatch(
      setClouldk2Slice({
        isBurnLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: BURN_USER_STATUS,
      })
      .then((response: any) => {
        const { data } = response?.data;

        dispatch(setBurnUserStatus(data));

        return data;
      })
      .catch((error: any) => {
        if (error.data) {
          toast.error(error.data);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
      });
  };

  const postBurnToken = async () => {
    dispatch(
      setClouldk2Slice({
        isBurnLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: BURN_TOKEN,
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data) {
          toast.error(error?.data?.data?.message || error?.data?.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
      });
  };

  const getBurnWalletBalance = async () => {
    dispatch(
      setClouldk2Slice({
        isBurnLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: BURN_WALLET_BALANCE,
      })
      .then((response: any) => {
        const { data } = response?.data;

        dispatch(setBurnWalletBalance(data));

        return data;
      })
      .catch((error: any) => {
        if (error.data) {
          toast.error(error.data);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setClouldk2Slice({
            isBurnLoading: false,
          })
        );
      });
  };

  return {
    getBurnLimit,
    getBurnCalculation,
    getUserRewardsData,
    getClaimableReward,
    getLifetimeReward,
    getUserMachinesList,
    getUserPurchasedMachines,
    getUserMachineById,
    getGlobalAutoCompound,
    setGlobalAutoCompound,
    postUserGlobalClaimRewards,
    setGlobalMachineAutoCompound,
    getUserMachineRewards,
    postStakeIntoMachine,
    getAllProducts,
    getActiveBurn,
    getBurnUserStatus,
    getBurnWalletBalance,
    postBurnToken,
    postJoinBurnCompaign,
  };
};
