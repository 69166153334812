import React from 'react';

const WalletWhite = ({ color = 'white' }: { color?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6666 31.6654V8.33203C36.6666 5.57061 34.428 3.33203 31.6666 3.33203H8.33325C5.57183 3.33203 3.33325 5.57061 3.33325 8.33203V31.6654C3.33325 34.4268 5.57183 36.6654 8.33325 36.6654H31.6666C34.428 36.6654 36.6666 34.4268 36.6666 31.6654ZM26.6666 16.6654C24.8256 16.6654 23.3333 18.1577 23.3333 19.9987C23.3333 21.8396 24.8256 23.332 26.6666 23.332C28.5075 23.332 29.9999 21.8396 29.9999 19.9987C29.9999 18.1577 28.5075 16.6654 26.6666 16.6654ZM33.3333 14.9987H21.6666C20.7461 14.9987 19.9999 15.7449 19.9999 16.6654V23.332C19.9999 24.2525 20.7461 24.9987 21.6666 24.9987H33.3333V14.9987ZM31.6666 6.66536C32.5871 6.66536 33.3333 7.41156 33.3333 8.33203V11.6654H21.6666C18.9052 11.6654 16.6666 13.9039 16.6666 16.6654V23.332C16.6666 26.0935 18.9052 28.332 21.6666 28.332H33.3333V31.6654C33.3333 32.5858 32.5871 33.332 31.6666 33.332H8.33325C7.41278 33.332 6.66659 32.5858 6.66659 31.6654V8.33203C6.66659 7.41156 7.41278 6.66536 8.33325 6.66536H31.6666Z"
        fill={color}
      />
    </svg>
  );
};

export default WalletWhite;
