import { Alert } from '@mui/material';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import TotalSNGPCard from './TotalSNGPCard';
type TotalSNGPCardProps = {
    title: string;
    totalSNGP: number;
    loading: boolean;
}
const TotalSNGP: FC<TotalSNGPCardProps> = ({ title, totalSNGP, loading }) => {

    return (
        <div className='!bg-[#E8F5FB] w-full rounded-[4px] h-[2.8rem] flex justify-between items-center px-5'>
            <TotalSNGPCard title={title} totalSNGP={totalSNGP} loading={loading} />
        </div>
    )
}

export default TotalSNGP;