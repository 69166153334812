import { ReactNode } from 'react';
import GlobalSkeleton from '../../../components/Global/GlobalSkeleton';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';

type CardType = {
  title: string;
  children?: ReactNode;
  btn?: boolean;
  loading?: boolean;
  showInfoIcon?: boolean;
  infoIcon?: any;
};
const CardComponent = ({
  title,
  children,
  btn,
  loading,
  showInfoIcon,
  infoIcon,
}: CardType) => {
  return (
    <HoverBorderAnimation className="flex flex-col flex-1 placeholder:flex-1 md:px-6 md:py-5 xxs:px-4 xxs:py-4 rounded-[8px] border border-solid  border-borderBlue  box-border  bg-white radius-12px">
      {loading ? (
        <>
          <div className="flex justify-between w-full xxs:flex-col md:flex-col lg:flex-col xl:flex-row">
            <div className="text-sm font-medium text-secondaryBlack xxs:text-lg">
              <GlobalSkeleton animationValue="pulse" height={40} width={80} />
            </div>
          </div>
          <div className="box-border flex items-center justify-between">
            <div>
              {Array(5)
                .fill({})
                .map((itm, idx) => {
                  return (
                    <GlobalSkeleton
                      key={idx}
                      animationValue="pulse"
                      height={30}
                      width={80}
                    />
                  );
                })}
            </div>
            <div>
              {Array(5)
                .fill({})
                .map((itm, idx) => {
                  return (
                    <GlobalSkeleton
                      key={idx}
                      animationValue="pulse"
                      height={30}
                      width={150}
                    />
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between w-full md:items-center xl:flex-row">
            <h1 className="text-sm font-medium text-secondaryBlack xxs:text-lg">
              {title}
            </h1>
            {showInfoIcon && infoIcon}
          </div>
          <div className="box-border flex-1">{children}</div>
        </>
      )}
    </HoverBorderAnimation>
  );
};

export default CardComponent;
