import React, { useEffect, useState } from 'react';
import CloudKTable from '../../../Common/CloudKTable/CloudKTable';
import CloudKSelect from '../../../Common/CloudKSelect/CloudKSelect';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import {
  formatAlphaNumericDate,
  formatNumberWithCommas,
  toFixedFormat,
  toFixedWithoutRoundingOff,
} from '../../../../../../utils/utils';
import RangeDatePicker from '../../../../../Wallet/RangeDatePicker';
import moment from 'moment';
import { EmptyWithdrawHistoryGif } from '../../../../../../images/wallets';
import GetCSVData from '../../../../../../components/GetCSVData';
import Tooltips from '../../../../../../components/Tooltips';
import { InfoIcon } from '../../../../../../images/svgs';

const columns = [
  { label: 'Sr. No', key: 'id', classNames: 'text-center', width: "10%" },
  { label: 'License ID', key: 'licenseId', width: "10%" },
  { label: 'Transaction Type', key: 'transactionType', width: "10%" },
  { label: 'Amount', key: 'amount', width: "10%" },
  { label: 'Validity', key: 'endDate', width: "15%" },
  { label: 'Token', key: 'token', width: "10%" },
  { label: 'From', key: 'from', width: "10%" },
  { label: 'Date', key: 'date', width: "10%" },
];

const TransactionHistory = () => {
  const {
    transactionHistoryData,
    transactionHistoryLoading,
    allLiciencesIds,
    allLiciencesStatus,
  } = useSelector(selectCloudSlice);
  const { getCloudKTransactionHistory, getAllLicienceIDsFunc } =
    useCloudkActions();
  const [licenseIdsOption, setLicenseIdsOption] = useState([]);
  const [allLicensesStatusArray, setAllLicensesStatusArray] = useState<any>([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [downloadTransaction, setDownloadTransaction] = useState([]);
  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    licenseId: null,
    licensesStatus: null,
    fromDate: null,
    toDate: null,
  });
  const [resetDisable, setResetDisable] = useState(true);
  useEffect(() => {
    const noData = !transactionHistoryData?.list?.length;
    const hasFilters =
      filters.licenseId ||
      filters.licensesStatus ||
      filters.fromDate ||
      filters.toDate;

    // Disable if (no data and no filters) OR (data and no filters)
    setResetDisable((noData && !hasFilters) || (!noData && !hasFilters));
  }, [transactionHistoryData, filters]);

  useEffect(() => {
    if (allLiciencesIds?.length > 0) {
      setLicenseIdsOption(
        allLiciencesIds.map((item: any) => ({
          value: item.licenseId,
          label: item.identifier,
        }))
      );
    }
  }, [allLiciencesIds]);

  useEffect(() => {
    if (allLiciencesStatus.length > 0) {
      const statusArray: any = allLiciencesStatus.map((license: any) => ({
        label: license,
        value: license,
      }));

      setAllLicensesStatusArray(statusArray);
    }
  }, [allLiciencesStatus]);

  useEffect(() => {
    if (!allLiciencesStatus) {
      getAllLicienceIDsFunc();
    }
    // getAllLicensesStatusFunc();
  }, []);
  useEffect(() => {
    setResetFilter(false);

    getCloudKTransactionHistory({
      page: filters.page,
      limit: filters.limit,
      id: filters?.licenseId?.value,
      type: filters?.licensesStatus?.value,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
    });

  }, [filters]);

  const RenderTag = (type: string, reason = null) => {

    switch (type) {
      case 'purchase':
        return (
          <span className="py-1 px-2 bg-[#CCEFFF] text-[#147AA7] rounded-md text-xs font-medium">
            Purchase
          </span>
        );
      case 'add-stake':
        return (
          <span className="py-1 px-2 bg-[#0B6D5E1A] text-[#0B6D5E] rounded-md text-xs font-medium">
            Add Stake
          </span>
        );
      case 'reward':
        return (
          <span className="py-1 px-2 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium">
            Rewards
          </span>
        );
      case 'claimed':
        return (
          <span className="py-1 px-2 bg-[#DAEFFF] text-[#1B9FDA] rounded-md text-xs font-medium">
            Claimed Rewards
          </span>
        );
      case 'link-license':

        return (
          <span className="py-1 px-2 bg-[#0B6D5E1A] text-[#0B6D5E] rounded-md text-xs font-medium">
            License Linked
          </span>
        );
      case 'd-link-license':
        return (
          <div className='flex justify-start items-start'>
            <span className="py-1 px-2 pt-1 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium flex flex-row items-center justify-center">
              License De-Linked
              {
                reason && <Tooltips
                  containerClass={" ml-2 !mt-0 !self-center !items-center justify-center"}
                  content={
                    <div className="p-3 bg-white shadow-md rounded-lg text-xs ">
                      <div className=" font-normal">
                        Auto-delinked due to Expired License
                      </div>
                    </div>
                  }
                  placement="top-end"
                >
                  <InfoIcon className={`w-4 h-4 cursor-pointer`} />
                </Tooltips>
              }
            </span>
          </div>
        );
      case 'claimed-collateral':
        return (
          <span className="py-1 px-2 bg-[#DAEFFF] text-[#1B9FDA] rounded-md text-xs font-medium">
            Claimed Collateral
          </span>
        );
      default:
        return null; // Or a fallback message/component if needed
    }
  };
  const rows =
    transactionHistoryData?.list?.map((transaction: any, index: number) => ({
      id: (filters.limit * filters.page + index + 1 - 10).toString().padStart(2, '0'),
      // licenseId: transaction.userLicenseId,
      licenseId: transaction?.userLicenseinfo[0]?.identifier,
      transactionType: RenderTag(transaction.type, transaction?.meta?.reason ?? null),
      amount: transaction?.tokenAmount ? toFixedFormat(
        transaction?.tokenAmount ?? 0, 4) : transaction?.totalPrice ??
      '-',
      token: transaction?.token?.tokenName ?? '-',
      endDate: (transaction?.type === 'purchase' || transaction?.type === 'add-stake') ? formatAlphaNumericDate(transaction?.type === 'add-stake' ? transaction?.lockedLicense?.stakeEndDate : transaction?.lockedLicense?.endDate) : "-",
      from: transaction.from ?? '-',
      date: formatAlphaNumericDate(transaction.createdAt),
    })) || [];
  const handleLicenseChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      page: 1,
      licenseId: selectedOption,
    }));
  };
  const handleTypeChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      page: 1,
      licensesStatus: selectedOption,
    }));
  };

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');

    // setDate({ fromDate: from, toDate: to });
    setFilters((prev: any) => ({
      ...prev,
      page: 1,
      fromDate: from,
      toDate: to,
    }));
  };
  const getFilteredApiData = async () => {
    const res = await getCloudKTransactionHistory({
      page: filters.page,
      limit: transactionHistoryData?.totalCount,
      id: filters?.licenseId?.value,
      type: filters?.licensesStatus?.value,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
    }, false);
    return res.data?.data?.list;


  };
  const filterDownloadData = (data: any) => {

    const result = data.map((dt: any, index: number) => {
      return {
        'Sr.no': (filters.limit * filters.page + index + 1 - 10).toString().padStart(2, '0'),
        'License ID': dt?.userLicenseinfo[0]?.identifier,
        'Transaction Type': dt.type,
        'Amount': toFixedFormat(
          dt?.tokenAmount ?? dt.totalPrice ?? 0,
          4
        ),
        'Token': dt?.token?.tokenName ?? '-',
        'From': dt.from ?? '-',
        'Date': formatAlphaNumericDate(dt.createdAt),
      };
    });

    return setDownloadTransaction(result);

  };
  const noDataUI = <div className="flex-1 flex justify-center items-center w-full mt-5">
    <div className="flex flex-col gap-2 items-center ">
      <div className=" w-36 h-auto">
        <img src={EmptyWithdrawHistoryGif} alt="empty transactions" />
      </div>
      <div className="flex flex-col gap-1 items-center">
        <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
          Sorry, No Transactions History Found.
        </span>

      </div>
    </div>
  </div>;
  return (
    <div className="bg-[#FFFFFF] ">
      <CloudKTable
        rows={rows}
        columns={columns}
        title="Transaction History"
        loading={transactionHistoryLoading}
        // loading={true}

        currentPage={filters.page}
        totalPages={transactionHistoryData?.totalPages ?? 1}
        onPageChange={(page) => setFilters((prev: any) => ({ ...prev, page }))}
        renderNoData={noDataUI}
        dataRowClass='h-[60px]'

        filters={[
          <CloudKSelect
            options={licenseIdsOption}
            placeholder="License ID"
            onChange={handleLicenseChange} // Handle the option change
            selectedOption={filters.licenseId}
          />,
          <CloudKSelect
            selectedOption={filters.licensesStatus}
            options={allLicensesStatusArray}
            placeholder="Transaction Type"
            onChange={handleTypeChange}
          />,
          <RangeDatePicker
            handleDateDate={handleDateDate}
            resetFilter={resetFilter}
            toDateClassName={`!h-9 text-secondaryBlack text-sm`}
            fromDateClassName={`!h-9 text-secondaryBlack text-sm`}
          // setDateChanged={true}
          // fromDateDisable={resetDisable}
          // fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          // toDateDisable={resetDisable}
          // toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          />,
          <button
            className={`${resetDisable ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
            type="button"
            disabled={resetDisable}
            onClick={() => {
              setResetFilter(true);
              setFilters({
                limit: 10,
                page: 1,
                licenseId: null,
                licensesStatus: null,
                fromDate: null,
                toDate: null,
              });
            }}
          >
            Reset
          </button>,
          <GetCSVData
            disabled={transactionHistoryData?.totalPages <= 0}
            transactionData={downloadTransaction}
            fileName={`CloudkTransactionhistory.csv`}
            classes="flex justify-end"
            onclick={() => {
              if (transactionHistoryData?.list?.length > 0) {
                getFilteredApiData().then((data: any) => {
                  filterDownloadData(data);
                });
              }
            }}
            onDownloadReady={() => {
              // Callback function to handle any additional logic if needed
            }}
          />
        ]}
      // navigationContainerStyle="!justify-center"
      // totalPagesProp={transactionHistoryData?.totalPages}
      // currentPageProp={transactionHistoryData?.currentPage}
      />
    </div>
  );

  function getTotalPrice(transaction: any): React.ReactNode {
    return formatNumberWithCommas(
      Number(toFixedWithoutRoundingOff(Number(transaction.totalPrice), 2))
    );
  }
};

export default TransactionHistory;
