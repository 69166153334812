import { Key, ReactElement, useEffect, useState } from 'react';
import { IMyTree, TListingMembers } from '../../../types/superNodes.type';
import ListingMemberLoading from './ListingTreeNodeLoading';
import NoData from '../../../components/Table/NoData';
import ListingTreeNode from './ListingTreeNode';
import { getRandomColor } from '../../../utils/helper';

function ListingMembers({
  data,
  view,
  loading,
  isChild = false,
}: TListingMembers): ReactElement {
  const [randomColor, setRandomColor] = useState('#ffff');

  useEffect((): void => {
    setRandomColor(getRandomColor());
  }, []);
  return (
    <div className="font-montserrat relative">
      <div className="relative mt-4 flex flex-col gap-2 w-full">
        <div className="flex gap-5 justify-between">
          <h4 className="text-black text-lg font-medium mb-2">Rewards Tree</h4>
        </div>
        {loading && data.length === 0 ? (
          Array(5)
            .fill({})
            .map((_: any, index: number): ReactElement => {
              return <ListingMemberLoading key={index} />;
            })
        ) : data.length > 0 ? (
          data.map((item: IMyTree, index: Key): ReactElement => {
            return (
              <div className="" key={index}>
                <ListingTreeNode
                  data={item}
                  view={view}
                  isChild={isChild}
                  randomColor={randomColor}
                />
              </div>
            );
          })
        ) : (
          <div className="bg-white border-[1px] rounded-md font-normal text-base text-titlePrimary relative">
            <NoData
              visible={data?.length < 1}
              text={'No Rewards Tree'}
              textClassName="!text-defaultBlack"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ListingMembers;
