const WarningIcon = ({
  className,
  color = 'white',
}: {
  className?: string;
  color?: string;
}) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.1044 18.6887L15.3456 3.52202C15.1079 3.11033 14.766 2.76847 14.3543 2.53078C13.9426 2.2931 13.4756 2.16797 13.0002 2.16797C12.5248 2.16797 12.0578 2.2931 11.6461 2.53078C11.2344 2.76847 10.8925 3.11033 10.6548 3.52202L1.89605 18.6887C1.65826 19.1006 1.53311 19.5678 1.5332 20.0434C1.5333 20.519 1.65862 20.9861 1.89658 21.3979C2.13453 21.8097 2.47672 22.1516 2.88873 22.3891C3.30074 22.6267 3.76804 22.7516 4.24363 22.7512H21.7568C22.2322 22.7512 22.6992 22.626 23.1109 22.3883C23.5226 22.1506 23.8645 21.8087 24.1022 21.397C24.3399 20.9853 24.4651 20.5182 24.4651 20.0428C24.4651 19.5674 24.3399 19.1004 24.1022 18.6887H24.1044Z"
        fill="white"
      />
      <path
        d="M12.9999 16.2513C13.2837 16.2526 13.5577 16.1477 13.7682 15.9573C13.9787 15.7669 14.1103 15.5047 14.1374 15.2221L14.7765 8.83047C14.7993 8.60428 14.7743 8.37583 14.7032 8.1599C14.6321 7.94396 14.5165 7.74534 14.3639 7.57688C14.2113 7.40841 14.025 7.27384 13.8171 7.18188C13.6092 7.08991 13.3843 7.04259 13.157 7.04297H12.8428C12.6157 7.04297 12.3911 7.09057 12.1835 7.1827C11.976 7.27484 11.79 7.40946 11.6376 7.57788C11.4853 7.7463 11.3699 7.94479 11.299 8.16054C11.2281 8.37629 11.2032 8.6045 11.2259 8.83047L11.8651 15.2221C11.8921 15.5042 12.0234 15.766 12.2333 15.9564C12.4431 16.1468 12.7165 16.2519 12.9999 16.2513Z"
        fill={color}
      />
      <path
        d="M13.0003 20.5846C14.0474 20.5846 14.8962 19.7358 14.8962 18.6888C14.8962 17.6418 14.0474 16.793 13.0003 16.793C11.9533 16.793 11.1045 17.6418 11.1045 18.6888C11.1045 19.7358 11.9533 20.5846 13.0003 20.5846Z"
        fill={color}
      />
    </svg>
  );
};

export default WarningIcon;
