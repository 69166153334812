/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { DownloadIcon } from '../images/svgs';
import GlobalButton from './Global/GlobalButton';

type TGetCSVData = {
  onclick: () => void;
  transactionData: any;
  disabled: boolean;
  onDownloadReady: () => void;
  fileName?: string;
  classes?: string;
};

const GetCSVData = ({
  onclick,
  transactionData,
  disabled,
  onDownloadReady,
  fileName = 'transactions.csv',
  classes,
}: TGetCSVData) => {
  const csvLink = useRef<any>();

  useEffect(() => {
    if (transactionData.length > 0) {
      onDownloadReady();
      csvLink.current.link.click();
    }
  }, [transactionData]);

  return (
    <div className={`${classes}`}>
      <GlobalButton
        text="Download"
        type="button"
        disabled={disabled}
        StartIcon={
          <DownloadIcon className="text-white relative z-10 w-4 h-4 col-span-1" />
        }
        classNames="!w-[10%] rounded-lg font-normal !text-sm !py-[10px] px-5 min-w-max place-item-end"
        onClick={onclick}
      />
      <CSVLink
        data={transactionData}
        filename={fileName}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};

export default memo(GetCSVData);
