import React from 'react';

function PlatformComingSoon() {
  return (
    <div className="flex items-center justify-center h-full absolute top-0 w-full">
      COMING SOON
    </div>
  );
}

export default PlatformComingSoon;
