import { mobileHardwareImgStep3 } from '../../../../../../images/cloudk';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
const Step3 = () => {
  return (
    <div className="">
      <div className="w-full flex flex-col gap-2 items-center">
        <div className="w-full flex justify-center my-4">
          <img src={mobileHardwareImgStep3} alt="" className="h-48 sm:h-[350px]" />
        </div>
        <p className=" text-center">
          Please click on
          <span className="py-1 px-3  rounded-sm  bg-[#00ccf9] text-white text-xs mx-1 inline-block">
            <QrCodeScannerIcon className="mr-1" fontSize="small" />
            Scan QR Code Now
          </span>
          to scan the QR code and link your phone.
        </p>
        <p className="text-[#F0AD47]  text-sm">
          Note : undo the shutter of the camera of the phone
        </p>
      </div>
    </div>
  );
};

export default Step3;
