import { useSelector } from 'react-redux';
import StepLine from '../../../../components/StepLine';
import StepNumber from '../../../../components/StepNumber';
import { RootState } from '../../../../store';
import {
  DEPOSIT_SLICE_NAME,
  StateDeposit,
} from '../../../../store/wallets/depositSlice';

type Props = {
  step: number;
  enableBackground: any;
  lineEnable?: boolean;
};

const DepositStepper = ({
  step,
  enableBackground = null,
  lineEnable = true,
}: Props) => {
  const { currentStep }: StateDeposit = useSelector(
    (state: RootState) => state[DEPOSIT_SLICE_NAME]
  );

  return (
    <div className="h-full transition-all">
      <StepNumber
        number={step}
        enableBackground={enableBackground}
        active={step === currentStep}
      />
      {lineEnable && <StepLine enableBackground={enableBackground} />}
    </div>
  );
};

export default DepositStepper;
