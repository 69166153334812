import React from 'react';
import AppTooltip from '../../pages/ExternalApp/AppTooltip';
import Tooltips from '../Tooltips';

interface AppsSwiperProps {
  cards1: any[];
  loginClick: (id: string) => void;
}

const MobilePlatforms = ({ cards1, loginClick }: AppsSwiperProps) => {
  return (
    <div className="w-full flex flex-col gap-4 py-4 lg:hidden">
      {Array.from({ length: 2 }, (_, i) => (
        <div
          className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)] group"
          key={i}
        >
          <ul
            className={`flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none ${i === 1 ? 'animate-infinite-scroll-vertical' : 'animate-infinite-scroll-vertical-reverse'} group-hover:animation-play-state-paused cursor-pointer`}
          >
            {cards1.map((card, index) => (
              <React.Fragment key={index}>
                <Tooltips
                  content={
                    <AppTooltip
                      name={card.name}
                      version={'Web 2.0'}
                      description={card.description}
                    />
                  }
                >
                  <li>
                    <img
                      src={card?.logo}
                      className="bg-white/30 h-20 w-20 p-3 rounded-lg"
                      onClick={() => loginClick(card.id)}
                      alt="card_logo"
                    />
                  </li>
                </Tooltips>
              </React.Fragment>
            ))}
          </ul>
          <ul
            className={`flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none ${i === 1 ? 'animate-infinite-scroll-vertical' : 'animate-infinite-scroll-vertical-reverse'} group-hover:animation-play-state-paused cursor-pointer`}
            aria-hidden="true"
          >
            {cards1.map((card, index) => (
              <React.Fragment key={index}>
                <Tooltips
                  content={
                    <AppTooltip
                      name={card.name}
                      version={'Web 2.0'}
                      description={card.description}
                    />
                  }
                >
                  <li>
                    <img
                      src={card?.logo}
                      className="bg-white/30 h-20 w-20 p-3 rounded-lg"
                      onClick={() => loginClick(card.id)}
                      alt="card_logo"
                    />
                  </li>
                </Tooltips>
              </React.Fragment>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MobilePlatforms;
