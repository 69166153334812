import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Manila } from '../../images/svgs';
import DesktopPlatforms from './DesktopPlatforms';
import MobilePlatforms from './MobilePlatforms';

export interface Card {
  icon: React.ReactNode;
  name: string;
  version: string;
  description: string;
}

interface AppsSwiperProps {
  cards1: Card[];
  cards2: Card[];
  cards3: Card[];
  loginClick: (id: string) => void;
}

const AnimatedPlatforms = ({
  cards1,
  cards2,
  cards3,
  loginClick,
}: AppsSwiperProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div className="flex flex-col lg:flex-row justify-between relative bg-gradient-to-r from-[#1B9FDA] to-[#84D3ED] h-full rounded-xl px-5 overflow-hidden">
      {/* Text Section */}
      <div className="text-white flex flex-col gap-5 p-8">
        <p className="font-bold text-xl">Top Grossing</p>
        <p className="font-medium text-base ">
          Get rewards like never before. Join the featured community.
        </p>

        {/* <div>
          <GlobalButton
            text="View All"
            animate={true}
            classNames="!border !border-white"
          />
        </div> */}
        <div className="absolute bottom-0 self-center">
          <Manila />
        </div>
      </div>

      {/* For Desktop  */}
      {isDesktop ? (
        <DesktopPlatforms cards1={cards1} loginClick={loginClick} />
      ) : (
        <MobilePlatforms cards1={cards1} loginClick={loginClick} />
      )}

      {/* For Mobile  */}
    </div>
  );
};

export default AnimatedPlatforms;
