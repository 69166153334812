import { mobileHardwareImgStep2 } from '../../../../../../images/cloudk';

const Step2 = () => {
  return (
    <div className="">
      <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center my-4">
          <img src={mobileHardwareImgStep2} alt="" className="h-48 sm:h-[350px]" />
        </div>
        <p className=" text-center">
          Please read the content on the following screens on the mobile app and
          press next
        </p>
      </div>
    </div>
  );
};

export default Step2;
