/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useRef, useState } from 'react';
import useUserCheck from '../hooks/useUserCheck';
import LoadingButton from './button/LoadingButton';

function AuthOTP({
  vCode,
  setVCode,
  isOpen,
  onClose,
  handleContinueClick,
  isVerifying,
  errorMessage,
  otpMode,
  title = 'Verify the code',
  handleSendAgainClick = (e?: any) => {},
  loadershow = false,
}: {
  vCode: any;
  setVCode: any;
  isOpen: any;
  onClose: any;
  handleContinueClick: any;
  isVerifying?: boolean;
  errorMessage?: string;
  otpMode: string;
  title?: string;
  handleSendAgainClick?: (e?: any) => void;
  loadershow?: boolean;
}) {
  const inputRefs = useRef<HTMLInputElement[]>(Array(6).fill(null));
  const [resendCount, setResendCount] = useState(0);
  const [emptyIndex, setEmptyIndex] = useState<any>({});

  // const startCountDown = () => {
  //     if (resendCount <= 0) {
  //         // setResendCount(0)
  //         return
  //     }
  //     setTimeout(() => {
  //         const val = resendCount
  //         // setResendCount(val - 1)
  //         // startCountDown()
  //     }, 1000)
  // }
  //     BLANK
  // TOP_BALANCE
  // PREFFERED_TOKEN - fromToken

  const restartCountDown = () => {
    if (resendCount > 0) return;
    setResendCount(60);

    const timer = setInterval(() => {
      setResendCount((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        return prev;
      });
      if (resendCount < 0) {
        clearInterval(timer);
        return;
      }
    }, 1000);
  };

  // const user = useSelector(selectUser)
  const { user } = useUserCheck();

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (!value) {
      setEmptyIndex({ ...emptyIndex, [index]: true });
    } else {
      setEmptyIndex({ ...emptyIndex, [index]: false });
    }
    if (isNaN(Number(value))) return;
    const updatedOtp = [...vCode];
    updatedOtp[index] = value;
    setVCode(updatedOtp);

    if (value && index < vCode.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const handleInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text');
    const otpArray = pastedData
      .slice(0, 6) // Limit to 6 characters
      .split('')
      .map((char) => (isNaN(Number(char)) ? '' : char));
    setVCode(otpArray);
  };

  const handleInputKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && !event.currentTarget.value && index > 0) {
      event.preventDefault();
      inputRefs.current[index - 1]?.focus();
    }
  };

  const showMessage: any = {
    EMAIL: `Please check your inbox for verification code sent to ${user?.email.substring(
      0,
      2
    )}************${user?.email.slice(-1)}`,
    TWO_FA: 'Please input the code from your authenticator app',
    CODE: 'Please input your secret security code',
  };

  useEffect(() => {
    if (isOpen) {
      restartCountDown();
    } else {
      setEmptyIndex({});
    }
  }, [isOpen]);
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      sx={{
        '.MuiDialog-paper': {
          backgroundColor: '#0A0A0A',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
        },
      }}
      maxWidth="xs"
      fullWidth={true}
    >
      <div className="flex flex-col items-center w-full p-2">
        <div className="flex justify-end w-full">
          <button
            className="p-2"
            onClick={() => {
              setVCode(Array.from({ length: 6 }, () => ''));
              onClose();
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="px-10 pb-10 pt-2 flex flex-col items-center gap-2">
          {/* <img src={subscribe} alt="Subscribe to Newsletter" /> */}
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-xl font-medium">{title}</h1>
            <p className="text-center text-sm text-secondary">
              {showMessage[otpMode] || showMessage.EMAIL}
            </p>
          </div>
          <div className="flex flex-row justify-between gap-2">
            {/* Updated styles for input elements */}
            {Array.from({ length: 6 }, (_, index) => (
              <input
                key={index}
                required={true}
                type="text"
                maxLength={1}
                className={`border w-12 h-12 text-xl text-center outline-none bg-transparent rounded ${
                  emptyIndex[index] &&
                  (vCode[index] ? 'border-secondary' : '!border-red-500')
                }`}
                ref={(el) => (inputRefs.current[index] = el!)}
                value={vCode[index]}
                onChange={(e) => handleInputChange(index, e)}
                onPaste={handleInputPaste}
                onKeyDown={(e) => handleInputKeyDown(index, e)}
              />
            ))}
          </div>
          {errorMessage ? (
            <span className="text-center text-red-500 text-xs pt-1">
              {errorMessage}
            </span>
          ) : (
            ''
          )}

          {/* {
                        handleSendAgainClick && (
                            <CountdownTimer
                                handleSendAgainClick={handleSendAgainClick}
                                isOpen={isOpen}
                                loadershow={loadershow}
                            />
                        )
                    } */}

          {/* <div
                        className="flex justify-end"
                        onClick={restartCountDown}
                    >
                        <span className="text-blue-400 hover:text-white cursor-pointer">
                            {resendCount > 0 ? resendCount : "Resend"}
                        </span>
                    </div> */}
          <div className="flex flex-col items-center w-full pt-3">
            <LoadingButton
              className="w-full"
              loading={isVerifying}
              loadingType="circular"
              buttonType="button"
              onClick={() => handleContinueClick()}
            >
              Verify
            </LoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AuthOTP;
