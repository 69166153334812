/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalHeader from '../../components/Global/GlobalHeader';
import { useGeneralActions } from '../../store/general/generalActions';
import {
  selectSupport,
  setSupportList,
  setSupportLoader,
} from '../../store/general/generalSlice';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';

const Support = () => {
  const dispatch = useDispatch();
  const { getSupports } = useGeneralActions();
  const { supportList, supportLoader } = useSelector(selectSupport);
  let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const fetchSupportList = async () => {
    dispatch(setSupportLoader(true));
    try {
      const response = await getSupports();
      if (response) {
        dispatch(setSupportList(response.data));
      }
    } finally {
      dispatch(setSupportLoader(false));
    }
  };

  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  React.useEffect(() => {
    fetchSupportList();
  }, []);

  return (
    <div className="flex flex-col w-full gap-10">
      <GlobalHeader title="Support" />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {supportList?.length === 0 && supportLoader ? (
          <>
            {Array(4)
              .fill('')
              .map(() => (
                <GlobalSkeleton
                  animationValue="wave"
                  variant="rectangular"
                  width={'100%'}
                  className="relative w-full bg-white border rounded-lg shadow-lg cursor-pointer"
                  height={170}
                />
              ))}
          </>
        ) : (
          supportList?.length &&
          [...supportList].map((list, index) => {
            return (
              <div
                className="relative w-full bg-white border rounded-lg shadow-lg cursor-pointer border-primary"
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <AnimatePresence>
                  {hoveredIndex === index && (
                    <motion.span
                      className="absolute -inset-2 h-[100% + 20px] w-[100% + 40px] bg-primary/20 blocK  rounded-lg "
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.15, delay: 0.2 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <div className="relative z-10 flex flex-col h-full gap-4 py-4 bg-white rounded-lg ">
                  <div className="flex items-center justify-between">
                    <div className=" flex h-[1px] w-[10%] bg-primary" />
                    <div className="flex items-center justify-center w-12 h-12 border rounded-full border-primary">
                      {list?.icon && (
                        <img
                          src={list.icon}
                          className="object-contain w-7 h-7"
                          alt="list"
                        />
                      )}
                    </div>
                    <div className="flex h-[1px] w-[85%] bg-primary" />
                  </div>
                  <div className="flex items-center">
                    <div className="h-[1px] w-[10%]" />
                    <div className="text-2xl font-medium">
                      {list?.logo ? (
                        <img src={list?.logo} alt="Support" className="h-6" />
                      ) : (
                        <>{list?.name}</>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center bg-blue">
                    <div className="h-[1px] w-[10%]" />
                    <button
                      className="px-4 py-1 text-lg text-white rounded-lg bg-primary"
                      onClick={() => openLinkInNewTab(list.link)}
                    >
                      Create Ticket
                    </button>
                  </div>
                </div>
                <div className="absolute bottom-0 right-0 z-20 flex justify-end opacity-20">
                  {list?.background && (
                    <img
                      src={list.background}
                      alt=""
                      className="h-[140px] object-contain self-end"
                    />
                  )}
                </div>
              </div>
            );
          })
        )}
        {/* {supportList?.length > 0
          ? [...supportList].map((list, index) => {
              return (
                <div
                  className="relative w-full bg-white border rounded-lg shadow-lg cursor-pointer border-primary"
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <AnimatePresence>
                    {hoveredIndex === index && (
                      <motion.span
                        className="absolute -inset-2 h-[100% + 20px] w-[100% + 40px] bg-primary/20 blocK  rounded-lg "
                        layoutId="hoverBackground"
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: { duration: 0.15 },
                        }}
                        exit={{
                          opacity: 0,
                          transition: { duration: 0.15, delay: 0.2 },
                        }}
                      />
                    )}
                  </AnimatePresence>
                  <div className="relative z-10 flex flex-col h-full gap-4 py-4 bg-white rounded-lg ">
                    <div className="flex items-center justify-between">
                      <div className=" flex h-[1px] w-[10%] bg-primary" />
                      <div className="flex items-center justify-center w-12 h-12 border rounded-full border-primary">
                        {list?.icon && (
                          <img
                            src={list.icon}
                            className="object-contain w-7 h-7"
                            alt="list"
                          />
                        )}
                      </div>
                      <div className="flex h-[1px] w-[85%] bg-primary" />
                    </div>
                    <div className="flex items-center">
                      <div className="h-[1px] w-[10%]" />
                      <div className="text-2xl font-medium">
                        {list?.logo ? (
                          <img src={list?.logo} alt="Support" className="h-6" />
                        ) : (
                          <>{list?.name}</>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center bg-blue">
                      <div className="h-[1px] w-[10%]" />
                      <button
                        className="px-4 py-1 text-lg text-white rounded-lg bg-primary"
                        onClick={() => openLinkInNewTab(list.link)}
                      >
                        Create Ticket
                      </button>
                    </div>
                  </div>
                  <div className="absolute bottom-0 right-0 z-20 flex justify-end opacity-20">
                    {list?.background && (
                      <img
                        src={list.background}
                        alt=""
                        className="h-[140px] object-contain self-end"
                      />
                    )}
                  </div>
                </div>
              );
            })
          : null} */}
      </div>
    </div>
  );
};

export default Support;
