export const homnifiLogo = '/img/Logo/homnifiLogo.svg';
export const profileImg = '/img/Profile/profile.jpg';
export const homnifiStake = '/img/Stake/HomnifiStake.png';
export const homnifiMachine = '/img/Stake/machine.png';
export const horysmallStake = '/img/Stake/HorysmallStake.png';
export const SmStake = '/img/Stake/SmStake.png';
export const USDKPromoStake = '/img/Stake/USDKPromoStake.png';
export const editIcon = '/img/icons/edit.svg';
export const dummyUser = '/img/Profile/user1.png';
export const emptyBox = '/img/empty-box.png';
export const dummyUserLarge = '/img/Profile/dummyUserLarge.png';
export const bestValue = '/img/best-value.png';
export const tickIcon = '/img/tick-icon.png';
export const cloudLykLogo = '/img/cloud/LYK 1.png';
export const christmasFooter = '/img/christmas/christmas_footer.png';
export const christmasCap = '/img/christmas/christmas_cap.png';
export const christmasImage1 = '/img/christmas/christmas_image1.png';
export const christmasImage2 = '/img/christmas/christmas_image2.png';
export const christmasImage3 = '/img/christmas/christmas_image3.png';
export const christmasImage4 = '/img/christmas/christmas_image4.png';
export const christmasImage5 = '/img/christmas/christmas_image5.png';
export const christmasSnow = '/img/christmas/christmas_snow.png';
export const christmasBall = '/img/christmas/christmas_ball.png';
export const hangingBalloons1 = '/img/christmas/hanging_balloons1.png';
export const hangingBalloons2 = '/img/christmas/hanging_balloons2.png';
export const christmasPapa = '/img/christmas/christmas_papa.png';
export const christmasPapa2 = '/img/christmas/christmas_papa_2.png';
export const christmasPapa3 = '/img/christmas/christmas_papa_3.png';
export const ChristmasSMToken = '/img/christmas/christmas_sm_token.png';
export const christmasPapaGift = '/img/christmas/christmas_papa_gift.png';
export const christmasPapaPeek = '/img/christmas/christmas_papa_peek.png';
