import React from 'react';

type Props = {
  className?: string;
  text: string;
};

const GlobalSubheading = ({ text, className }: Props) => {
  return (
    <h2 className={`text-defaultBlack font-medium text-lg ${className}`}>
      {text}
    </h2>
  );
};

export default GlobalSubheading;
