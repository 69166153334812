



import React from 'react';

const WithdrawIcon = ({ color = '#292929' }: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70833 8.29167L15.5167 2.48334"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0833 5.31669V1.91669H12.6833"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.29167 1.91669H6.875C3.33334 1.91669 1.91667 3.33335 1.91667 6.87502V11.125C1.91667 14.6667 3.33334 16.0834 6.875 16.0834H11.125C14.6667 16.0834 16.0833 14.6667 16.0833 11.125V9.70835"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WithdrawIcon;
