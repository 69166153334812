/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import ScoreHistoryCommunity from './_components/ScoreHistoryCommunity';
import GlobalHeading from '../../../components/Global/GlobalHeading';
import UserSNGPCommunity from './_components/UserSNGPCommunity';
import SNGPRecords from './_components/SNGPRecords';
import TotalSNGP from './_components/TotalSNGP';
import SNGPPie from './_components/SNGPPie';
import { useSNGPPoolActions } from '../../../store/sngpPool/sngpPoolActions';
import { selectSNGPPoolSlice } from '../../../store/sngpPool/sngpPoolSlice';
import BannerComp from './_components/BannerComp';

export default function SNGP(): JSX.Element {
  const {
    activity,
    activityLoading,
    totalClaimed,
    totalSngp,
    sngpRecords,
    userSNGPCommunityLoading,
    userSNGPCommunity,
    history,
    historyLoading,
    sngpBannerLoading,
    sngpBannerData,
    claimLoading,
    claimedDetails,
  } = useSelector(selectSNGPPoolSlice);

  const {
    getSNGPActivity,
    getSNGPCommunityGraph,
    getSNGPScoreHistory,
    getSNGPBannerData,
  } = useSNGPPoolActions();

  const fetchSNGPBanner = async () => {
    if (!sngpBannerLoading && !sngpBannerData) {
      await getSNGPBannerData();
    }
  };

  const fetchSNGPActivity = async () => {
    if (!activityLoading && !activity) {
      await getSNGPActivity();
    }
  };

  // const fetchSNGPScoreHistory = async () => {
  //   if (!historyLoading) {
  //     await getSNGPScoreHistory({ page: 1, limit: 10 });
  //   }
  // };
  const fetchSNGPCommunityGraph = async () => {
    if (!userSNGPCommunityLoading && !userSNGPCommunity) {
      await getSNGPCommunityGraph({ timeline: 'weekly' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSNGPBanner();
    fetchSNGPActivity();
    // fetchSNGPScoreHistory();
    fetchSNGPCommunityGraph();
  }, []);

  return (
    <Stack direction={'column'} spacing={{ xs: 2, lg: 6 }}>
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent={'space-between'}
        alignItems={{ xs: 'start', lg: 'center' }}
        spacing={2}
      >
        <GlobalHeading
          heading={'SNGP Pools'}
          className="!text-defaultBlack  !text-2xl md:!text-4xl w-full lg:w-60"
        />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          width={{ xs: '100%', xl: '52%' }}
          bgcolor={'#FFFFFF'}
          padding={'4px'}
          border={'4px'}
        >
          <TotalSNGP
            title="Total SNGP"
            totalSNGP={totalSngp?.totalSngp || 0}
            loading={activityLoading}
          />
          <TotalSNGP
            title="Total SNGP Generated"
            totalSNGP={totalClaimed?.globalSngpUsed || 0}
            loading={activityLoading}
          />
        </Stack>
      </Stack>
      {sngpBannerData?.totalSngpPoints ? <BannerComp /> : <></>}
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
        <SNGPPie
          data={
            activity
              ? activity
              : {
                  balance: 0,
                  generated: 0,
                }
          }
          loading={activityLoading}
        />
        <SNGPRecords
          title="SNGP Statistics"
          data={sngpRecords || []}
          loading={activityLoading}
          totalClaimed={totalClaimed?.globalSngpUsed || 0}
          claimedDetails={
            claimedDetails
              ? claimedDetails
              : {
                  claimedAmount: 0,
                  claimedDate: '',
                  rewardToken: '',
                }
          }
        />
      </Stack>
      <UserSNGPCommunity
        title="User SNGP In Your Community"
        data={userSNGPCommunity || []}
        loading={userSNGPCommunityLoading}
      />
      <ScoreHistoryCommunity
        title="Your Score History"
        data={history}
        loading={historyLoading}
      />
    </Stack>
  );
}
