import React from 'react';

const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-4 right-6 cursor-pointer text-primaryGrey hover:text-black transition-all`}
      onClick={onClick}
      style={{ zIndex: 1 }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.41667 5.54168L12.375 9.50001L8.41667 13.4583"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default NextArrow;
