import {
  IMyTreeReward,
  ISuperNodeRewardsAnalytics,
  IUserDailyReward,
  TRewardAnalytics,
  UserStatsData,
} from '../../../types/superNodes.type';

export const processRewardAnalyticsDataFunc = (
  rewardAnalyticsData: TRewardAnalytics,
  superNodeRewardsAnalytics: ISuperNodeRewardsAnalytics | null
) => {
  return rewardAnalyticsData?.map((item) => {
    switch (item.label) {
      case 'Total Rewards':
        return {
          ...item,
          value: superNodeRewardsAnalytics?.totalRewards,
          unit: superNodeRewardsAnalytics?.rewardToken?.name,
        };
      case 'Base Referral Rewards':
        return {
          ...item,
          value: superNodeRewardsAnalytics?.baseReferralRewards,
          unit: superNodeRewardsAnalytics?.rewardToken?.name,
        };
      case 'Builder Generational':
        return {
          ...item,
          value: superNodeRewardsAnalytics?.builderGenerationalRewards ?? 0.0,
          unit: superNodeRewardsAnalytics?.rewardToken?.name ?? 'LYK-W',
        };
      case 'Builder Referral * 2':
        return {
          ...item,
          value: superNodeRewardsAnalytics?.builderReferralRewards ?? 0.0,
          unit: superNodeRewardsAnalytics?.rewardToken?.name ?? 'LYK-W',
        };
      case 'Claimable Rewards':
        return {
          ...item,
          value: superNodeRewardsAnalytics?.claimableRewards,
          unit: superNodeRewardsAnalytics?.rewardToken?.name,
        };
      default:
        return { ...item, value: 'Coming Soon' };
    }
  });
};

// export const processMyTreeDataFunc = (
//   myTreeRewardData: TRewardAnalytics,
//   myTreeData: IMyTreeReward | any
// ) => {
//   return myTreeRewardData?.map((item) => {
//     switch (item.label) {
//       case 'Total Nodes':
//         return {
//           ...item,
//           value: myTreeData.totalNodes,
//         };
//       case 'No. of Nodes in 1st Line':
//         return {
//           ...item,
//           value: myTreeData.firstLineNode,
//         };
//       case 'No. of Active Nodes in First Line':
//         return {
//           ...item,
//           value: myTreeData.activeFirstLineNode,
//         };
//       case 'Total Active Nodes':
//         return {
//           ...item,
//           value: myTreeData.activeTotalNode,
//         };
//       default:
//         return { ...item, value: 'Coming Soon' };
//     }
//   });
// };

export const processSngpScoreDataFunc = (
  sngpScoreData: TRewardAnalytics,
  sngpScoreResponse: any
) => {
  return sngpScoreData?.map((item) => {
    switch (item.label) {
      case 'Global SNGP':
        return {
          ...item,
          bigText: true,
          value: sngpScoreResponse?.totalAmount,
        };
      case 'Italy':
      case 'Canada':
      case 'USA':
      case 'UAE':
      default:
        return { ...item, value: 'Stay Tuned' };
    }
  });
};

export const userDetailFunc = (
  userStatsArray: UserStatsData[],
  userDailyReward: IUserDailyReward
) => {
  return userStatsArray?.map((item) => {
    switch (item.title) {
      case 'My Daily Rewards':
        return {
          ...item,
          value: userDailyReward.dailyRewards,
          token: userDailyReward.token.name,
        };
      case 'Daily Production':
        return {
          ...item,
          value: userDailyReward.dailyProduction,
          token: userDailyReward.token.name,
        };
      case 'My Total Rewards':
        return {
          ...item,
          value: userDailyReward.totalReward,
          token: userDailyReward.token.name,
        };
      case 'Total Production':
        return {
          ...item,
          value: userDailyReward.totalProduction,
          token: userDailyReward.token.name,
        };
      default:
        return item;
    }
  });
};
