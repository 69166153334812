/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect } from 'react';

import { ReactComponent as ArrowDownIcon } from '../../SVGIcons/arrowDown.svg';
import { RotatingLines } from 'react-loader-spinner';
import {
  selectedDropDown,
  setSelectedDropDown,
} from '../../store/general/generalSlice';
import { useDispatch, useSelector } from 'react-redux';

export interface IGlobalSelect {
  name?: string;
  value: any;
  disabled?: boolean;
  widget?: any;
  label?: string;
}

type TGlobalSelect = {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  options: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLDivElement>) => void;
  setFieldTouched?: any;
  setFieldValue?: any;
  formikk?: any;
  isError?: boolean;
  value?: string;
  className?: string;
  classNameSelect?: string;
  labelStyles?: string;
  showSearch?: boolean;
  disabled?: boolean;
  dropDownWithValues?: boolean;
  getSelectedValue?: (value: string) => void;
  selectedCountryName?: (name: any) => void | undefined;
  optionsWidth?: string;
  selectdisable?: boolean;
  loading?: boolean;
};

const GlobalSelect = ({
  name,
  label,
  required,
  placeholder,
  options,
  onBlur,
  onChange,
  setFieldTouched,
  isError,
  setFieldValue,
  formikk,
  value = '',
  className,
  classNameSelect,
  showSearch = true,
  dropDownWithValues,
  getSelectedValue,
  disabled,
  labelStyles,
  optionsWidth,
  selectedCountryName,
  selectdisable = false,
  loading = false,
}: TGlobalSelect) => {
  const dispatch = useDispatch();
  const dropDownSelected = useSelector(selectedDropDown);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(value || '');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState([...options]);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
    dispatch(setSelectedDropDown(name));
    setFieldTouched && setFieldTouched('country', true);
  };

  const handleSelectValue = useCallback((value: string) => {
    if (value) {
      setSelectedValue(value);
      setFieldValue && setFieldValue('country', value);
      formikk && formikk.setFieldValue('phone', '');
      getSelectedValue && getSelectedValue(value);
      setIsOpen(false);
      setSearchTerm('');
    }
  }, []);

  const filterOptions = (input: string) => {
    const filtered =
      options &&
      options?.filter((option: { name: string }) =>
        option.name.toLowerCase().includes(input.toLocaleLowerCase())
      );
    setFilteredOptions(filtered);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    filterOptions(e.target.value);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (dropDownSelected !== name) setIsOpen(false);
  }, [dropDownSelected]);

  return (
    <div className={`flex flex-col gap-2 text-sm ${className}`}>
      {label && (
        <div
          className={`${disabled ? 'font-normal' : 'font-medium'} ${labelStyles}`}
        >
          {label} {required && <span className="text-redDanger">*</span>}
        </div>
      )}
      {disabled ? (
        <span className={`font-medium ${disabled ? '!text-xs' : ''}`}>
          {selectedValue
            ? options.find(
                (option: { value: string }) => option.value === selectedValue
              )?.name
            : placeholder}
        </span>
      ) : (
        <div className="relative select-none">
          <button
            type="button"
            className={`bg-white border ${
              isError ? 'border-primaryRed text-primaryRed' : 'border-blueGrey '
            } py-2 px-4 text-xs rounded-full w-full min-h-9 ${selectedValue ? 'text-black ' : 'text-gray-400'} ${selectdisable ? 'cursor-not-allowed' : 'cursor-pointer'}  ${classNameSelect}`}
            onClick={toggleDropdown}
            onMouseDown={(e) => e.stopPropagation()}
            disabled={selectdisable}
          >
            {selectedValue
              ? options.find(
                  (option: { value: string }) => option.value === selectedValue
                )?.name
              : placeholder}

            <div className="absolute top-3 right-4">
              {loading ? (
                <div className="relative">
                  <RotatingLines
                    width="16"
                    strokeWidth="3"
                    animationDuration="0.75"
                    strokeColor={'#382F10'}
                  />
                </div>
              ) : (
                <ArrowDownIcon
                  className={`w-3 h-3 transform ${
                    isOpen ? 'rotate-180' : 'rotate-0'
                  } transition-transform duration-300 text-black`}
                />
              )}
            </div>
          </button>

          <div
            ref={dropdownRef}
            className={`text-xs flex flex-col w-full rounded-xl shadow-lg absolute top-10 left-0 border border-blueGrey bg-white overflow-auto max-h-52 ${
              isOpen
                ? 'translate-y-0 visible opacity-100'
                : '-translate-y-10 invisible opacity-0'
            } transition-all ease-in-out duration-300  ${optionsWidth}`}
            style={{ zIndex: 99 }}
          >
            {showSearch ? (
              <input
                type="text"
                placeholder="search..."
                className="outline-none p-2 border-b border-blueGrey"
                value={searchTerm}
                onChange={handleInputChange}
              />
            ) : (
              ''
            )}

            {filteredOptions.map((option, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  disabled={option?.disabled}
                  className={`p-2 border-b border-blueGrey hover:bg-gray-100 transition-all ease-in-out duration-300 ${option?.disabled ? 'cursor-not-allowed bg-slate-200 opacity-20' : 'cursor-pointer'}`}
                  onClick={() => {
                    handleSelectValue(option.value);
                    if (selectedCountryName) {
                      selectedCountryName(option?.name!);
                    }
                  }}
                >
                  {option.widget ?? option.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GlobalSelect);
