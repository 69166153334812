import React, { memo, ReactElement, useEffect, useState } from 'react';
import GlobalModal from '../../../components/Global/GlobalModal';
import { profileImg } from '../../../images';
import { useSuperNodeActions } from '../../../store/superNode/superNodeActions';
import { ReactComponent as Spinner } from '../../../SVGIcons/spinner.svg';
import { IChildern, TListingTreeNode } from '../../../types/superNodes.type';
import { formatDateFns, getRandomColor } from '../../../utils/helper';
import { toFixedWithoutRoundingOff } from '../../../utils/utils';
import ActiveIcon from '../../../images/active_icon.png';
import Inactive from '../../../images/inactive_icon.png';

const ListingTreeNode = ({
  data,
  view,
  isChild,
  isPopup,
  randomColor,
}: TListingTreeNode) => {
  const { getRewardsTree } = useSuperNodeActions();
  const [expanded, setExpanded] = useState(false);
  const [childs, setChilds] = useState<IChildern[]>([]);
  const [loading, setLoading] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const [randomColors] = useState<string>(() => getRandomColor());
  const [hasGrandChildren, setHasGrandChildren] = useState(true);

  const showChildDetails = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    if (isPopup) return;

    if (isPopup) {
      setUserDialog((prev: boolean): boolean => !prev);
    } else {
      setUserDialog((prev: boolean): boolean => !prev);
    }
  };

  const toggleExpansion = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: IChildern
  ): Promise<void> => {
    e.preventDefault();
    if (!expanded && childs.length === 0) {
      setLoading(true);
      try {
        const response = await getRewardsTree({
          depth: 1,
          userId: data?.user._id,
        });
        const fetchedChildren: any = response.data.data || [];
        setChilds(fetchedChildren);
        setHasGrandChildren(fetchedChildren.length > 0);
      } catch (error) {
        console.error('Error loading child data:', error);
      } finally {
        setLoading(false);
      }
    }
    setExpanded((prev: boolean): boolean => !prev);
  };

  useEffect((): void => {
    if (data?.children.length > 0) {
      setChilds(data.children);
    }
  }, [data.children]);

  const ShowChilds = (data: IChildern[]) =>
    data.length > 0 &&
    data.map((child: IChildern): ReactElement => {
      return (
        <div className="ml-8" key={child?._id}>
          <div className="relative">
            <ListingTreeNode
              data={child}
              view={view}
              isChild={true}
              isPopup={isPopup}
              randomColor={randomColor}
            />
          </div>
        </div>
      );
    });

  const {
    reward,
    production,
    status,
    baseReferralStatus,
    builderGenerationStatus,
    user: {
      blockchainId,
      profilePicture,
      firstName,
      lastName,
      username,
      email,
      dateJoined,
      createdAt,
    },
  } = data;


  return (
    <div className={`min-w-[1000px] pt-3`}>
      <div className="relative w-full ">
        <div
          className="pt-[5px] mx-[1.2rem] rounded-t-[.7rem]"
          style={{
            backgroundColor: randomColors,
          }}
        />
      </div>
      <div
        className="bg-white border-[1px] mx-3 rounded-lg shadow-md font-normal text-base text-titlePrimary relative"
        style={{
          borderColor: randomColors,
        }}
      >
        <div className="flex gap-5 cursor-pointer" onClick={showChildDetails}>
          <div className="w-[260px] ml-5 flex items-center gap-2 py-2">
            <img
              src={profilePicture || profileImg}
              alt="user icon"
              className="w-[50px] h-[50px] rounded-full  border-[2px] border-secondary"
              style={{
                borderColor: randomColors,
              }}
            />
            <div className="flex flex-col text-base">
              <span className="font-medium text-sm">
                {firstName || username || ''} {lastName || ''}
              </span>
              <span className="text-primaryGrey text-xs font-normal">
                {email || '-'}
              </span>
              <span className="text-primaryGrey text-xs font-normal">
                {blockchainId || '-'}
              </span>
            </div>
          </div>

          <div className="flex-1 grid grid-cols-5 md:grid-cols-5 xs:grid-cols-5 gap-3 md:gap-8 items-center text-16px font-normal">
            <div className="flex flex-col items-center justify-center">
              <span className="text-[0.6rem] text-primaryGrey font-normal">
                My Rewards
              </span>
              <span className="text-center text-xs">
                {toFixedWithoutRoundingOff(reward, 6) || 0} LYK-W
              </span>
            </div>

            <div className="flex flex-col gap-1 items-center justify-center">
              <span className="text-[0.6rem] text-primaryGrey font-normal">
                Production
              </span>
              <span className="text-xs text-center">
                {toFixedWithoutRoundingOff(production, 6) || 0} LYK-W
              </span>
            </div>

            <div className="flex flex-col gap-1 items-center justify-center">
              <span className="text-[0.6rem] text-primaryGrey font-normal">
                Base Referral
              </span>
              <span className={`text-xs text-center text-cardPrimaryText}`}>
                {baseReferralStatus ? (
                  <div className={'flex flex-row gap-2 items-center'}>
                    <div className="flex w-4 h-4 bg-green-500  rounded-full justify-center items-center ">
                      <img
                        src={ActiveIcon}
                        alt={'Active'}
                        style={{ objectFit: 'contain', width: 15, height: 15 }}
                      />
                    </div>
                    Active
                  </div>
                ) : (
                  <div className={'flex flex-row gap-2 items-center'}>
                    <div className="flex w-4 h-4 bg-red-600 rounded-full justify-center items-center ">
                      <img
                        src={Inactive}
                        alt={'Inactive'}
                        style={{ objectFit: 'contain', width: 8, height: 8 }}
                      />
                    </div>
                    Inactive
                  </div>
                )}
              </span>
            </div>

            <div className="flex flex-col gap-1 items-center justify-center">
              <span className="text-[0.6rem] text-primaryGrey font-normal">
                Builder Generational
              </span>
              <span className={`text-xs text-center text-cardPrimaryText`}>
                {builderGenerationStatus ? (
                  <div className={'flex flex-row gap-2 items-center'}>
                    <div className="flex w-4 h-4 bg-green-500 rounded-full justify-center items-center ">
                      <img
                        src={ActiveIcon}
                        alt={'Active'}
                        style={{ objectFit: 'contain', width: 15, height: 15 }}
                      />
                    </div>
                    Active
                  </div>
                ) : (
                  <div className={'flex flex-row gap-2 items-center'}>
                    <div className="flex w-4 h-4 bg-red-600 rounded-full justify-center items-center ">
                      <img
                        src={Inactive}
                        alt={'Inactive'}
                        style={{ objectFit: 'contain', width: 8, height: 8 }}
                      />
                    </div>
                    Inactive
                  </div>
                )}
              </span>
            </div>

            <div className="flex flex-col gap-1 items-center justify-center">
              <span className="text-[0.6rem] text-primaryGrey font-normal">
                Date Joined
              </span>
              <span className="text-xs text-center">
                {dateJoined
                  ? formatDateFns(dateJoined, 'yyyy-MM-dd')
                  : formatDateFns(createdAt, 'yyyy-MM-dd')}
              </span>
            </div>
          </div>
        </div>
        {hasGrandChildren && (
          <div
            className="absolute top-[38%] border-[1px] rounded-full w-5 h-5 border-cardText -left-[10px] bg-cardLight/10 cursor-pointer flex justify-center items-center"
            onClick={(e): void => {
              toggleExpansion(e, data);
            }}
          >
            <div className="flex justify-center items-center">
              <div className="text-lg font-bold leading-none">
                {loading ? (
                  <div className="flex flex-col gap-1">
                    <Spinner className="animate-spin h-3" />
                  </div>
                ) : expanded && childs.length > 0 ? (
                  '-'
                ) : (
                  '+'
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {expanded && ShowChilds(data?.children)}

      <GlobalModal
        size="5xl"
        fullWidthContent="!max-w-[2000px] overflow-auto"
        isOpen={userDialog}
        onClose={(): void => setUserDialog(false)}
        title={`Rewards Tree`}
      >
        <div className="screen1700:max-w-[1600px] screen1400:max-w-[1170px] screen1200:max-w-[1020px] max-h-[30rem] overflow-auto  small-scroll">
          <div className="font-montserrat relative ">
            <div className="relative mt-4 flex flex-col gap-2 w-full">
              <ListingTreeNode
                data={data}
                view={view}
                isChild={false}
                isPopup={true}
                randomColor={randomColors}
              />
            </div>
          </div>
        </div>
      </GlobalModal>
    </div>
  );
};

export default memo(ListingTreeNode);
