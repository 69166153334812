/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { sliderData } from '../../data/authSlider';
import { selectDrawerSlice } from '../../store/drawer/drawerSlice';
import { christmasBall, christmasSnow } from '../../../src/images/index';
import { FEATURES } from '../../utils/constants';
type Props = {};
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaydelay: 10000,
  autoplayspeed: 10000,
  afterChange: (change: any) => {},
  customPaging: (i: any) => (
    <button key={i} data-key={i} name={'my_custom_' + i}></button>
  ),
  appendDots: (dots: any) => (
    <ul style={{ margin: '0px' }} onClick={(dot: any) => {}}>
      {dots}
    </ul>
  ),
};
// const backgroundImages = [
//   LayoutImage1,
//   LayoutImage2,
//   LayoutImage3,
//   LayoutImage2,
// ];

const AuthSliderWrapper = (props: Props) => {
  const { activeSlider } = useSelector(selectDrawerSlice);
  let sliderRef: any = React.useRef<any>(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(activeSlider);
    }
  }, [activeSlider, sliderRef.current]);

  return (
    <div className="hidden items-center justify-center w-[40%] md:flex relative">
      <div className="relative w-full z-[0]">
        {FEATURES.showChristmas && (
          <img
            src={christmasBall}
            className="z-[8] absolute bottom-[124px] lg:bottom-[252px] -left-[56px]"
          />
        )}
        <div
          className="absolute -top-8 -left-8 w-52 h-80 p-1 bg-gradient-to-r from-primary to-primaryLight rounded-tl-[4rem] rounded-br-[4rem] overflow-hidden"
          style={{ zIndex: 0 }}
        >
          <div className="bg-white w-full h-full rounded-tl-[3.8rem] rounded-br-[3.8rem]" />
        </div>

        <div
          className="relative max-w-96 lg:h-[40rem] h-[32rem] w-full overflow-hidden rounded-tl-[4rem] rounded-br-[4rem]"
          style={{ zIndex: 99 }}
        >
          {FEATURES.showChristmas && (
            <img
              src={christmasSnow}
              className="z-[100] absolute right-0 -top-[10px]"
            />
          )}
          <div className="relative">
            <Slider
              {...settings}
              ref={sliderRef}
              className="w-full authSlider "
            >
              {sliderData.map((slider, index) => (
                <div key={index}>
                  <div
                    className={`bg-cover bg-no-repeat bg-center w-full lg:h-[42rem] h-[32rem]`}
                    style={{
                      backgroundImage: `url(${slider.image})`,
                    }}
                  >
                    <div className="relative w-full h-full cursor-pointer select-none bg-gradient-to-b from-transparent via-transparent to-primaryDark/60 bottom-14">
                      <div className="flex items-end justify-center w-full h-full text-white">
                        <div className="px-5">
                          <div className="flex flex-col w-full gap-2 p-2 text-center rounded-lg bg-white/10 backdrop-blur-md">
                            <div className="text-lg font-baumans">
                              {slider.title}
                            </div>
                            <span className="text-xs font-light">
                              {slider.text}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* <div
            className="relative w-full h-full bg-gradient-to-b from-transparent via-transparent to-primaryDark/60"
            style={{ zIndex: 10 }}
          >
            <AuthSlider sliderRef={sliderRef} />
          </div> */}
        </div>
        <div
          className="absolute -bottom-8 -right-8 w-52 h-80 p-1 bg-gradient-to-l from-primary to-primaryLight rounded-tl-[4rem] rounded-br-[4rem] overflow-hidden"
          style={{ zIndex: 0 }}
        >
          <div className="bg-white w-full h-full rounded-tl-[3.8rem] rounded-br-[3.8rem]" />
        </div>
      </div>
    </div>
  );
};

export default AuthSliderWrapper;
