import { FC } from 'react';
import SNGPRecordsProgressBar from './SNGPRecordsProgressBar';
import GlobalSkeleton from '../../../../../components/Global/GlobalSkeleton';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation';
import { format } from 'date-fns';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../../../utils/utils';
import { useSelector } from 'react-redux';
import { selectRewardSlice } from '../../../../../store/Cloudk2/rewardCalculatorSlice';

type SNGPRecordsProps = {
  title: string;
  data: {
    label: string;
    totalRecords: number;
    generatedRecords: number;
  }[];
  loading: boolean;
  totalClaimed: number | null;
  claimedDetails: {
    claimedAmount: number;
    claimedDate: string;
    rewardToken: string;
  };
};

const SNGPRecords: FC<SNGPRecordsProps> = ({
  title,
  data,
  loading,
  claimedDetails,
}) => {
  const formatDate = (isoString: any) => {
    if (!isoString) return null;
    const date = new Date(isoString);
    return format(date, 'dd-MM-yyyy - HH:mm');
  };

  const { LYK } = useSelector(selectRewardSlice);

  return (
    <HoverBorderAnimation
      className={`h-full border radius-5px rounded-lg flex w-full xl:w-1/2 bg-white  relative `}
      hoverAnimation={true}
    >
      <div className="flex  w-full   rounded-[8px] bg-white border-[#E6E6E6] ">
        <section className="flex flex-col flex-1 gap-6 p-4 px-6 ">
          <div className={'flex flex-2 '}>
            <p
              className={`text-xl font-medium w-full`}
              style={{ color: 'black' }}
            >
              {title}
            </p>
          </div>
          <div className="flex flex-col items-center gap-4">
            {loading && data.length === 0
              ? Array(3)
                  .fill({})
                  .map((item: any) => {
                    return (
                      <div className="w-full">
                        <GlobalSkeleton
                          animationValue="wave"
                          className="w-24 h-2"
                          height={10}
                        />
                        <GlobalSkeleton
                          animationValue="wave"
                          className="w-full h-4"
                          height={44}
                        />
                      </div>
                    );
                  })
              : data &&
                data?.map((item: any) => {
                  return <SNGPRecordsProgressBar data={item} />;
                })}
          </div>
          <div className="w-full h-10 border-t border-slate-200">
            <div className="flex justify-between w-full gap-3 mt-2">
              <div>
                <h2 className="text-[10px] xs:text-[12px] sm:text-[15px] ">
                  Total Amount Claimed :
                </h2>
                {claimedDetails?.claimedDate ? (
                  <p className="text-[8px] md:text-[10px]  text-gray-500">
                    Claimed Date: {formatDate(claimedDetails?.claimedDate)}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <p className="flex flex-col items-end text-[8px] xs:text-[12px] sm:text-[15px]  font-semibold">
                {formatNumberWithCommas(claimedDetails?.claimedAmount)}{' '}
                {claimedDetails.rewardToken}{' '}
                <p className="text-[8px] md:text-[13px]">
                  {' '}
                  {`$${formatNumberWithCommas(
                    Number(
                      toFixedWithoutRoundingOff(
                        claimedDetails?.claimedAmount * LYK,
                        2
                      )
                    ) || 0
                  )}`}
                </p>
              </p>
            </div>

            {/* {claimedDetails?.claimedDate ? (
              <p className="text-[8px]  text-gray-500">
                Claimed Date: {formatDate(claimedDetails?.claimedDate)}
              </p>
            ) : (
              <></>
            )} */}
          </div>
        </section>
      </div>
    </HoverBorderAnimation>
  );
};

export default SNGPRecords;
