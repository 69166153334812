import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import {
  setConnectionStatus,
  setDisconnectReason,
  setError,
  setIsConnected,
  setIsConnecting,
  setIsDisconnected,
  setSocket,
} from '../../store/socketIO/socketIOSlice';
import { SocketIOSConnectionStatus } from '../../types/socketIO.types';

const useSocketIO = (url: string, options?: Record<string, any>) => {
  const dispatch = useDispatch();

  let socketInstance: Socket | null = null;

  const establishSocketConnection = useCallback(() => {
    if (!socketInstance) {
      socketInstance = io(url, {
        ...options,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: options?.reconnectionRetries || 5,
      });

      socketInstance.on('connect', () => {
        console.info('Connected with socket.io');
        dispatch(setSocket(socketInstance!));
        dispatch(setIsConnected(true));
        dispatch(setIsDisconnected(false));
        dispatch(setIsConnecting(false));
        dispatch(setConnectionStatus(SocketIOSConnectionStatus.CONNECTED));
      });

      socketInstance.on('disconnect', (reason) => {
        console.info('Disconnected from socket.io:', reason);
        dispatch(setIsConnected(false));
        dispatch(setIsDisconnected(true));
        dispatch(setIsConnecting(false));
        dispatch(setConnectionStatus(SocketIOSConnectionStatus.DISCONNECTED));
        dispatch(setDisconnectReason(reason));
      });

      socketInstance.on('connect_error', (error) => {
        console.error('Connection error with socket.io:', error.message);
        dispatch(setIsConnected(false));
        dispatch(setIsDisconnected(false));
        dispatch(setIsConnecting(true));
        dispatch(setConnectionStatus(SocketIOSConnectionStatus.ERROR));
        dispatch(setError(error.message));
      });
    }
  }, [url, options, dispatch]);

  const disconnectSocket = useCallback(() => {
    if (socketInstance) {
      socketInstance.disconnect();
      socketInstance = null;
    }
  }, []);

  return { establishSocketConnection, disconnectSocket };
};

export default useSocketIO;
