import { FC } from 'react';
import { formatNumberWithCommas } from '../../../../../utils/utils';
type SNGPRecordsProgressBarProps = {
    data: {
        label: string;
        totalRecords: number;
        generatedRecords: number;
    }
}
const SNGPRecordsProgressBar: FC<SNGPRecordsProgressBarProps> = ({ data: { label, generatedRecords, totalRecords } }) => {
    return (
        <div className="flex flex-col w-full">
            <p className="text-xs font-normal text-black mb-1">{label}</p>
            <div className="relative w-full flex justify-start items-center border border-gray-300 rounded-lg  bg-gradient-to-r from-gray-100 to-white h-7">
                <div
                    className=" text-xs text-blue-50 text-center leading-none rounded-md h-7"
                    style={{
                        width: `${((generatedRecords / totalRecords) * 100)}%`,
                        backgroundImage:
                            "linear-gradient(to right, #00A0E3, #04A1E3, #11A6E4, #28AFE6, #47BBE8,#6ECBEB,#88D5EE)",
                    }}
                >
                </div>
                <div className="absolute inset-0 flex flex-row justify-between items-center px-3 ">
                    <p className="text-[0.7rem] text-center leading-none font-normal text-black">{formatNumberWithCommas(generatedRecords)}</p>
                    <p className="text-[0.7rem] leading-none font-normal text-black">{formatNumberWithCommas(totalRecords)}</p>
                </div>
            </div>
        </div>
    )
}

export default SNGPRecordsProgressBar;