import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { ReactComponent as Spinner } from '../SVGIcons/spinner.svg';

type QrCodeProp = { value: string, size?: string };

export default function QrCode({ value, size = '' }: QrCodeProp) {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoader(false), 1000);
  }, [value]);

  return (
    <div className={`h-auto ${size ? `max-w-[${size}]` : 'max-w-[134px]'}  w-full p-2 border border-dashed border-[#AAAAAA]`}>
      {loader || value === '' ? (
        <div className="flex justify-center items-center h-24">
          <Spinner className="animate-spin h-10 text-primary" />
        </div>
      ) : (
        <QRCode
          value={value}
          viewBox={`0 0 256 256`}
          className={`h-auto mx-auto ${size ? `max-w-[${size}]` : 'max-w-[134px]'} w-full`}
        />
      )}
    </div>
  );
}
