import { useSelector } from 'react-redux';
import {
  selectWalletData,
  TokenData,
  WalletTokenWithPercentage,
} from '../../store/walletData/walletDataSlice';
import WalletWhite from '../../SVGIcons/Wallet-White';
import GlobalButton from '../../components/Global/GlobalButton';
import { useNavigate } from 'react-router-dom';
import { walletCardBg } from '../../images/wallets';
import PlusSquareIcon from '../../SVGIcons/Wallet/PlusSquareIcon';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';
import { Meteors } from '../../components/ui/Meteors';
import HoverBorderAnimation from '../../components/HoverBorderAnimation';
import { formatNumberWithCommas } from '../../utils/utils';
const TotalBalanceComp = ({ totalBalance }: { totalBalance: number }) => {
  const { walletList } = useSelector(selectWalletData);
  const navigate = useNavigate();
  const { loading } = useSelector(selectWalletData);

  const walletTokenAndPercentagesAndColor: WalletTokenWithPercentage[] =
    walletList?.wallets
      ? walletList?.wallets
          ?.filter((item: TokenData) => item.tokenBalance > 0) // Filter wallets with balance > 0
          .map((item: TokenData) => ({
            token: item.token.name,
            percentage: item.percentage,
            color: item.token.color,
          }))
      : [];
  return (
    <div
      className={`xl:col-span-4 col-span-12 relative rounded-[20px] px-4 radius-22px ${totalBalance > 0 ? ' bg-myWalletCard ' : ''} bg-cover bg-center  `}
    >
      {/* {loading ? (
        <div className="flex items-center justify-center flex-1 h-full">
          <div className="items-center self-center justify-center">
            <div className="flex flex-col gap-5">
              <Spinner className="h-6 animate-spin" />
            </div>
          </div>
        </div>
      ) : ( */}
      <div
        className={`${totalBalance > 0 ? 'overflow-hidden relative h-full' : 'h-full'}`}
      >
        {totalBalance > 0 ? (
          <Meteors
            number={20}
            className={`${loading && walletList?.total === 0 ? 'hidden' : ''}`}
          ></Meteors>
        ) : null}
        <>
          <div
            className={` ${totalBalance > 0 ? 'relative' : 'absolute'}  inset-0 rounded-[20px] ${
              totalBalance <= 0 ? 'grayscale opacity-50' : ''
            }`}
            style={{
              backgroundImage: `url(${walletCardBg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: 0,
            }}
          ></div>

          <div className="relative flex flex-col justify-between h-full gap-12 z-99">
            <div
              className={`flex xxs:gap-6 sm:gap-8 text-white mt-3  ${Number(totalBalance) <= 0 && 'flex-wrap-reverse items-end'}`}
            >
              {loading && walletList?.total === 0 ? (
                <GlobalSkeleton
                  variant="rounded"
                  width={40}
                  height={40}
                  animationValue={'wave'}
                />
              ) : (
                <div className="w-10 h-10">
                  <WalletWhite color={totalBalance > 0 ? 'white' : 'black'} />
                </div>
              )}

              <div className="flex flex-col gap-5 ">
                {loading && walletList?.total === 0 ? (
                  <GlobalSkeleton
                    variant="text"
                    sx={{ fontSize: '30px' }}
                    animationValue={'wave'}
                    width={'100px'}
                  />
                ) : (
                  <span
                    className={`${totalBalance > 0 ? 'text-white' : 'text-black '} text-3xl font-normal`}
                  >
                    Total Balance
                  </span>
                )}
                {loading && walletList?.total === 0 ? (
                  <GlobalSkeleton
                    variant="text"
                    sx={{ fontSize: '30px' }}
                    animationValue={'wave'}
                    width={'100px'}
                  />
                ) : (
                  <span
                    className={`${totalBalance > 0 ? 'text-white' : 'text-black'} xxs:text-[26px]  sm:text-4xl xl:text-3xl  font-semibold  text-shadow-totalBalanceBoxShadow`}
                  >
                    {walletList?.totalDollarValue &&
                    walletList?.totalDollarValue > 0
                      ? formatNumberWithCommas(Number(totalBalance?.toFixed(2)))
                      : '0.00'}{' '}
                    USDK
                  </span>
                )}
              </div>

              {Number(totalBalance) <= 0 && !loading && (
                <div className="relative ml-auto filter-none ">
                  <GlobalButton
                    StartIcon={<PlusSquareIcon />}
                    text="Deposit Now"
                    animate={false}
                    classNames="!rounded-[8px] font-normal tracking-wide !px-3 py-1 !w-max bg-primary filter-none text-sm font-medium  border-['#FFFFFF99] border "
                    onClick={() => navigate('/wallet/deposit')}
                  />
                </div>
              )}
            </div>
            {loading && walletList?.total === 0 ? (
              <GlobalSkeleton
                variant="rectangular"
                height={50}
                animationValue={'wave'}
                sx={{ borderRadius: '10px' }}
              />
            ) : (
              <div className="bg-[#FFFFFF4D] justify-around z-99 mb-4 py-3 px-1 text-white   flex   rounded-[10px] items-center xxs:gap-2 xs:gap-3 xl:gap-2 flex-wrap">
                {walletTokenAndPercentagesAndColor
                  .slice(
                    0,
                    walletTokenAndPercentagesAndColor.length > 3 ? 2 : 3
                  )
                  .map((item: WalletTokenWithPercentage, index: number) => (
                    <div
                      className="flex items-center xxs:gap-1 xs:gap-2 xl:gap-2"
                      key={index}
                    >
                      <div
                        className={`xxs:w-3 xxs:h-3 xs:w-4 xs:h-4 rounded-full`}
                        style={{
                          backgroundColor: `${totalBalance > 0 ? item.color : 'gray'}`,
                          border: '2px solid white',
                        }}
                      ></div>
                      <span
                        className={`${totalBalance > 0 ? '' : 'text-gray'}  xxs:text-[12px] sm:text-sm`}
                      >
                        {item.token} ({item.percentage}%)
                      </span>
                    </div>
                  ))}

                {/* Conditionally render "Others" if the array length is greater than 2 */}
                {walletTokenAndPercentagesAndColor.length > 3 && (
                  <div
                    className="flex items-center xxs:gap-1 xs:gap-2 xl:gap-1"
                    key="others"
                  >
                    <div
                      className="rounded-full xxs:w-3 xxs:h-3 xs:w-4 xs:h-4"
                      style={{
                        backgroundColor: `${totalBalance > 0 ? 'gray' : 'gray'}`, // Set color to gray
                        border: '2px solid white',
                      }}
                    ></div>
                    <span className="  xxs:text-[12px] sm:text-sm">Others</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default TotalBalanceComp;
