import React from 'react';

const BlackCircleInfoIcon = ({ color = '#292929' }: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M9.28815 6.25765C9.32279 6.01466 9.43781 5.81985 9.63319 5.67323C9.83885 5.5189 10.0632 5.45906 10.3062 5.4937C10.5492 5.52835 10.7433 5.64786 10.8887 5.85224C11.0443 6.0489 11.1048 6.26873 11.0701 6.51173C11.0355 6.75473 10.9153 6.95339 10.7097 7.10773C10.5143 7.25434 10.2951 7.31033 10.0521 7.27568C9.8091 7.24103 9.60979 7.12538 9.45417 6.92872C9.30884 6.72434 9.2535 6.50065 9.28815 6.25765ZM11.4403 13.4792L11.639 13.7279L11.6179 13.8764C11.4174 14.059 11.1886 14.2468 10.9315 14.4397C10.6757 14.6236 10.4064 14.7735 10.1236 14.8893C9.85104 14.9973 9.57978 15.0321 9.30978 14.9936C9.14778 14.9705 8.99992 14.9127 8.86619 14.8202C8.73117 14.7367 8.627 14.63 8.55369 14.5001C8.45979 14.3215 8.41152 14.1126 8.40888 13.8734C8.40625 13.6343 8.43894 13.3084 8.50697 12.8957C8.57628 12.474 8.6597 11.9211 8.75723 11.2371L8.98243 9.65763C8.99397 9.51235 8.9785 9.36322 8.93603 9.21024C8.90385 9.04954 8.79325 8.95572 8.60426 8.92877C8.46026 8.90824 8.30084 8.93142 8.12601 8.99833C7.96147 9.05751 7.78985 9.13405 7.61117 9.22795L7.43752 8.90016C7.6689 8.75868 7.91828 8.61976 8.18566 8.48341C8.46204 8.34834 8.73906 8.24091 9.01674 8.16113C9.29442 8.08134 9.56376 8.06006 9.82476 8.09727C9.95975 8.11652 10.0864 8.16213 10.2047 8.23409C10.3243 8.29705 10.4079 8.38703 10.4555 8.50401C10.4966 8.60171 10.5211 8.71998 10.5289 8.85883C10.5366 8.99768 10.5123 9.2651 10.4558 9.66109C10.4173 9.93109 10.3692 10.2043 10.3114 10.4807C10.2639 10.7494 10.2209 11.0188 10.1824 11.2888C10.1041 11.8378 10.0329 12.3051 9.9687 12.6909C9.91352 13.0779 9.91167 13.3806 9.96316 13.5992C10.0146 13.8177 10.1574 13.9437 10.3914 13.977C10.5534 14.0001 10.7301 13.9519 10.9217 13.8323C11.1132 13.7126 11.2861 13.595 11.4403 13.4792Z"
        fill={color}
      />
    </svg>
  );
};

export default BlackCircleInfoIcon;
