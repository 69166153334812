/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Animation from '../../components/Maintenance/Animation';
import { homnifiLogo } from '../../images';
import { PLATFORM_NAME } from '../../utils/constants';

const Maintenance = ({ maintenance }: any) => {
  const calculateTimeRemaining = () => {
    const now = moment.utc();
    const end = moment.utc(maintenance?.endDateTime);
    const duration = moment.duration(end.diff(now));

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [maintenance?.endDateTime]);

  return (
    <div className="flex flex-col gap-10 max-w-[1200px] items-center justify-center m-auto">
      <div className="flex justify-center w-full px-0 py-4 text-sm">
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME || ''} Logo`}
          className="w-32"
        />
      </div>

      <div className="flex flex-col items-center gap-10">
        <Animation />

        <div className="flex flex-col items-center gap-2">
          <div className="text-3xl font-baumans text-primary">
            Under Maintenance
          </div>
          {maintenance?.reason && (
            <span className="text-lg text-primaryGrey">
              {maintenance?.reason}
            </span>
          )}
          <span className="text-lg text-primaryGrey">
            Thank you for your cooperation. Our platform is under some cool
            changes. We will be back shortly.
          </span>
          {maintenance?.endDateTime && (
            <span className="text-lg text-primary">
              Issue will be resolved till{' '}
              <span className="font-medium">
                {moment.utc(maintenance?.endDateTime).format('Do MMMM, YYYY')}
              </span>
            </span>
          )}
          {maintenance?.endDateTime && (
            <div className="flex items-center gap-2 text-lg">
              <>
                <span className="flex items-center justify-center w-12 h-12 border border-primary text-primary  rounded">
                  {timeRemaining.days}d
                </span>
                <span className="flex items-center justify-center w-12 h-12 border border-primary text-primary rounded">
                  {timeRemaining.hours}h
                </span>
                <span className="flex items-center justify-center w-12 h-12 border border-primary text-primary rounded">
                  {timeRemaining.minutes}m
                </span>
                <span className="flex items-center justify-center w-12 h-12 border border-primary text-primary rounded">
                  {timeRemaining.seconds}s
                </span>
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
