import { FC, useEffect, useState } from 'react';
import { SNGPPieChart } from './SNGPPieChart';
import { ISNGPActivity } from '../../../../../types/sngpPool.type';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation';
import { PieChartDataInterface } from '../../../../../store/walletData/walletDataSlice';

type SNGPPieChartViewProps = {
  data: ISNGPActivity;
  loading: boolean;
};
const SNGPPie: FC<SNGPPieChartViewProps> = ({ data, loading }) => {
  const [activity, setActivity] = useState<ISNGPActivity[]>([]);

  const modifiedDataPieChart: any = activity
    ? activity
        // ?.filter((item: any) => Number(item.value) > 0)
        ?.map((item: any) => {
          return {
            id: item.id,
            label: item.label,
            value: item.value || 0,
          };
        })
    : [];

  const modifiedColorsPieChart = ['#FACC15', '#3B82F6'];

  const allValuesZero = modifiedDataPieChart.every(
    (wallet: PieChartDataInterface) => Number(wallet.value) === 0
  );

  const adjustedDataPieChart =
    allValuesZero || modifiedDataPieChart.length === 0
      ? Array.from({ length: 1 }, (_, index) => ({
          id: `Balance${index + 1}`,
          label: `Balance`,
          value: 100,
          actualValue: 0,
          name: `Balance${index + 1}`,
        }))
      : modifiedDataPieChart;

  useEffect(() => {
    const convertObjectIntoArray = Object.entries(data)?.map(
      ([key, value]) => ({
        id: key,
        label: capitalizeFirstLetter(key),
        value: value,
      })
    ) as [];
    setActivity(convertObjectIntoArray);
  }, [data]);

  return (
    <HoverBorderAnimation
      hoverAnimation={true}
      className="xl:col-span-4 col-span-12 radius-5px rounded-[8px] bg-white border border-[#E6E6E6] flex flex-col justify-between w-full xl:w-1/2"
    >
      <>
        <div className=" flex flex-col flex-1 rounded-[20px] relative bg-sngpPieChartBGImage bg-contain bg-center bg-no-repeat">
          <div
            className={`absolute inset-0 rounded-[20px] ${'grayscale opacity-50'}`}
          ></div>

          <div className="relative flex items-center justify-center h-full overflow-hidden walletPieChart ">
            <div className="xxs:h-[200px] xxs:w-[200px] lg:h-[250px] lg:w-[250px] 2xl:h-[300px] 2xl:w-[300px] ">
              <SNGPPieChart
                data={adjustedDataPieChart}
                colors={
                  allValuesZero || modifiedDataPieChart.length === 0
                    ? Array(6).fill('#E6E6E6')
                    : modifiedColorsPieChart
                }
                enableArcLinkLabels={true}
                enableArcLabels={true}
                innerRadius={0}
                padAngle={1}
                totalPercentage={100}
                isInteractive={
                  allValuesZero || modifiedDataPieChart.length === 0
                    ? false
                    : true
                }
                loading={loading}
              />
            </div>
          </div>
        </div>
      </>
    </HoverBorderAnimation>
  );
};

export default SNGPPie;
