import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from '@nextui-org/react';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  placement?:
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left-start'
  | 'left-end'
  | 'left'
  | 'right-start'
  | 'right-end'
  | 'right'
  | 'top-start'
  | 'top-end'
  | 'top';
  containerClass?: string;
  classContainer?: object;
  showArrow?: boolean;
  btnClass?: string;
  postionClass?: string
}

const Tooltips = ({
  content,
  children,
  placement = 'top-start',
  containerClass = '',
  classContainer = {},
  showArrow = false,
  btnClass = '',
  postionClass = ''
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Close the tooltip when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`flex justify-center mt-[-3px] ${containerClass} ${postionClass != '' ? postionClass : 'self-center right-0 md:self-end '}`}
      ref={tooltipRef}
    >
      <Tooltip
        placement={placement}
        isOpen={isOpen}
        style={{ marginRight: '-10px' }}
        showArrow={showArrow}
        classNames={classContainer}
        onMouseEnter={() => setIsOpen(true)}
        motionProps={{
          variants: {
            exit: {
              opacity: 0,
              y: 0,
              scale: 0.6,
            },
            enter: {
              opacity: 1,
              y: 0,
              scale: 1,
              transition: {
                type: 'spring',
                stiffness: 260,
                damping: 10,
              },
            },
          },
        }}
        content={
          <div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {content}
          </div>
        }
      >
        <button
          type="button"
          className={`inline-block ${btnClass} `}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(!isOpen)}
        >
          {children}
        </button>
      </Tooltip>
    </div>
  );
};

export default Tooltips;
