import { useSelector } from "react-redux"
import { formatNumberWithCommas, toFixedWithoutRoundingOff } from "../../../utils/utils"
import { selectSuperNodeSlice } from "../../../store/superNode/superNodeSlice"

const DailyReward = () => {
  const { dailySuperNodeRewards } = useSelector(selectSuperNodeSlice)
  return (
    <div className="flex justify-between md:gap-24 xxs:gap-5 items-center text-primary xxs:w-full md:w-auto">
      <span className="xxs:text-sm md:text-sm font-normal ">
        Yesterday's SuperNode Rewards
      </span>
      <div className="flex items-center gap-2">
        <span className="font-bold text-3xl">
          {formatNumberWithCommas(Number(toFixedWithoutRoundingOff(dailySuperNodeRewards?.totalToken || 0, 6)) || 0)}
        </span>
        <sub className="whitespace-nowrap">
          {dailySuperNodeRewards?.rewardToken?.name || 'LYK-W'}
        </sub>
      </div>
    </div>
  );
}

export default DailyReward