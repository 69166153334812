import { Favourite } from '../../images/svgs';
import GlobalButton from '../Global/GlobalButton';

interface Props {
  heading: string;
  description: string;
  image: string;
  buttonText: string;
  isFavourite: boolean;
  onFavouriteHandler: () => void;
  isButtonFill: boolean;
}

const PlatformCard: React.FC<Props> = ({
  heading,
  description,
  image,
  buttonText,
  isFavourite,
  onFavouriteHandler,
  isButtonFill,
}) => {
  return (
    <div className="col-span-2 lg:col-span-1 my-4">
      <div className="flex flex-col lg:flex-row md:justify-between md:items-center gap-4 md:gap-0">
        <div className="md:max-w-32">
          <div className="flex items-center justify-center h-20 w-20 border border-[#D9E4F1] rounded-full">
            <img src={image} alt="logo1" />
          </div>
        </div>
        <div className="flex flex-col md:mx-3 justify-between">
          <div className="mx-3">
            <div className="flex justify-start gap-4 items-center my-1">
              <div className="text-defaultBlack font-medium">{heading}</div>
              <button className="text-secondaryblue text-sm px-1 md:font-medium md:px-2 py-1 bg-[#EFF6FF] text-center">
                Web 3.0
              </button>
              <div onClick={onFavouriteHandler}>
                <Favourite
                  className={`w-8 h-8  ${isFavourite ? 'text-red-700  ' : ' text-white  '}`}
                />
              </div>
            </div>
          </div>
          <div className="mx-3 text-sm text-primaryGrey font-medium">
            {description}
          </div>
        </div>
        <div>
          <GlobalButton
            text={buttonText}
            type="button"
            outlined={isButtonFill ? false : true}
            classNames={`py-2 px-6 font-normal w-[8rem]`}
          />
        </div>
      </div>
    </div>
  );
};

export default PlatformCard;
